import { useContext } from 'react';
import cx from 'classnames';
import Skeleton from 'react-loading-skeleton';
import Flag from 'Components/Flag';
import { IOrderItemDataProps } from 'Pages/CartConfirmation/Components/OrderItem/interfaces';
import { getCountryCode } from 'Helpers/getFlagSpecification';
import { DeviceServiceContext } from 'Services/DeviceService';
import { LocalizationContext } from 'Services/LocalizationService';
import BundleProducer from 'Components/ProductBox/Components/BundleProducer';
import { getDiscountPriceValues } from 'vinisto_shared/src/price/get-discount-prices';
import { getLocalizedPrice } from 'vinisto_shared/src/price/get-localized-price';

const OrderItem = ({ orderItemData, isGift }: IOrderItemDataProps) => {
	const t = useContext(LocalizationContext).useFormatMessage();
	const deviceServiceContext = useContext(DeviceServiceContext);
	const isDesktop = deviceServiceContext.isDesktop;

	const bundle = orderItemData.bundle;
	const quantity = orderItemData.quantity ?? 0;

	if (bundle === null || bundle === undefined) return null;

	const basePrice = bundle?.price;

	const discountedPrice = bundle?.discountPrice;

	const { discountedPriceWithoutVat, discountedPriceWithVat, isDiscounted } =
		getDiscountPriceValues({
			quantityInBasket: quantity,
			basePrice,
			discountedPrice,
		});

	const priceCurrency = bundle.price?.currency;

	const producerSpecificationValue = bundle.producerSpecification?.value ?? '';

	const countrySpecification = bundle.countrySpecification ?? '';

	const supplierName = bundle.supplierDetail?.nameWeb ?? '';

	const isLoading = bundle === null;

	const priceWithVat = isDiscounted
		? discountedPriceWithVat
		: basePrice?.valueWithVat;

	const priceWithoutVat = isDiscounted
		? discountedPriceWithoutVat
		: basePrice?.value;

	const totalPriceWithoutVAT = (priceWithoutVat ?? 0) * quantity;

	const totalPriceWithVAT = (priceWithVat ?? 0) * quantity;

	return (
		<>
			<div className="vinisto-user-orders__orders__order-body__item vinisto-cart__item vinisto-order-summary desktop-only">
				<div className="vinisto-user-orders__orders__order-body__item__info">
					<div className="vinisto-user-orders__orders__order-body__item__info__img">
						{isLoading ? (
							<Skeleton
								height="80px"
								width="80%"
							/>
						) : (
							<img
								src={bundle.mainImage?.domainUrls?.thumb_64x80}
								alt={`${t({ id: 'alt.bundleImage' })}`}
							/>
						)}
					</div>
					<div className="vinisto-user-orders__orders__order-body__item__info__data">
						<div className="vinisto-user-orders__orders__order-body__item__info__data__name">
							{isLoading ? <Skeleton /> : bundle.name}
						</div>
						{isLoading ? (
							<Skeleton width="90%" />
						) : (
							<div className="vinisto-user-orders__orders__order-body__item__info__data__winery">
								<BundleProducer
									flag={
										<Flag
											code={getCountryCode(countrySpecification)}
											width="24"
											className="vinisto-flag"
										/>
									}
									name={producerSpecificationValue}
								/>
							</div>
						)}
					</div>
				</div>

				<div>
					{isLoading ? (
						<Skeleton
							count={1.6}
							width="80px"
						/>
					) : (
						<>
							<div className="vinisto-user-orders__orders__order-body__item__prices__total">
								<span className="fw-bolder">
									<span className="fw-light">{quantity} x </span>
									{getLocalizedPrice({
										price: priceWithVat ?? 0,
										// @ts-expect-error possible "undefined" issue
										currency: priceCurrency,
									})}
								</span>
							</div>
							<div className="vinisto-user-orders__orders__order-body__item__prices__total mt-1">
								{t(
									{ id: 'bundle.supplierSmall.name' },
									{
										name: (
											<span className="vinisto-color-success fw-bolder">
												{supplierName.length > 0
													? supplierName
													: t({
															id: 'productDetail.seller.name.others',
													  })}
											</span>
										),
									}
								)}
							</div>
						</>
					)}
				</div>

				<div className="vinisto-cart__item__price">
					<div className="vinisto-user-orders__orders__order-body__item__prices">
						{isLoading ? (
							<Skeleton
								count={1.6}
								width="80px"
							/>
						) : !isGift ? (
							<>
								<div className="vinisto-user-orders__orders__order-body__item__prices__total">
									{`${t({ id: 'basket.totalPrice' })} `}
									<span
										className={cx('fw-bold', {
											'vinisto-user-orders__orders__order-body__item__prices__big-price':
												isDesktop,
										})}
									>
										{getLocalizedPrice({
											price: totalPriceWithVAT,
											// @ts-expect-error possible "undefined" issue
											currency: priceCurrency,
										})}
									</span>
								</div>

								<div className="vinisto-user-orders__orders__order-body__item__prices__without-vat">
									{`${t({ id: 'basket.priceWithoutVAT' })} `}
									<span className="fw-bolder price-span">
										{getLocalizedPrice({
											price: totalPriceWithoutVAT,
											// @ts-expect-error possible "undefined" issue
											currency: priceCurrency,
										})}
									</span>
								</div>
							</>
						) : (
							<>
								<div className="vinisto-user-orders__orders__order-body__item__prices__total fw-bold vinisto-user-orders__orders__order-body__item__prices__big-price">
									{`${t({ id: 'basket.price.free' })} `}
								</div>
							</>
						)}
					</div>
				</div>
			</div>
			<div className="vinisto-user-favorite vinisto-cart__item--mobile tablet-mobile-only">
				<div className="vinisto-user-orders__orders__order-body">
					<div className="vinisto-user-orders__orders__order-body__item">
						<div className="vinisto-user-orders__orders__order-body__item__close"></div>
						<div className="vinisto-user-orders__orders__order-body__item__info__img">
							{isLoading ? (
								<Skeleton
									height="80px"
									width="80%"
								/>
							) : (
								<img
									src={bundle.mainImage?.domainUrls?.thumb_64x80}
									alt={`${t({ id: 'alt.bundleImage' })}`}
								/>
							)}
						</div>
						<div className="vinisto-user-orders__orders__order-body__item__info vinisto-cart-item-info">
							<div className="vinisto-user-orders__orders__order-body__item__info__data">
								<div className="vinisto-user-orders__orders__order-body__item__info__data__name mb-0">
									{isLoading ? <Skeleton /> : bundle.name}
								</div>
							</div>
							<div className="vinisto-user-orders__orders__order-body__item__info__data w-100">
								<div className="w-100">
									<div className="vinisto-user-orders__orders__order-body__item__info__data__winery">
										{isLoading ? (
											<Skeleton width="90%" />
										) : (
											<div className="vinisto-user-orders__orders__order-body__item__info__data__winery">
												<BundleProducer
													flag={
														<Flag
															code={getCountryCode(countrySpecification)}
															width="24"
															className="vinisto-flag"
														/>
													}
													name={producerSpecificationValue}
												/>
											</div>
										)}
									</div>
								</div>
								<div></div>
							</div>
							<div className="vinisto-user-orders__orders__order-body__item__info__data mt-2 flex-wrap align-items-start">
								<div className="vinisto-user-orders__orders__order-body__item__prices__without-vat d-flex flex-column">
									<div className="vinisto-order-finish__count">
										{t({ id: 'carousel.info.pcs' }, { count: quantity })}
									</div>
									<div className="tablet-mobile-only">
										{t(
											{ id: 'bundle.supplierSmall.name' },
											{
												name: (
													<span className="vinisto-color-success fw-bolder">
														{supplierName.length > 0
															? supplierName
															: t({
																	id: 'productDetail.seller.name.others',
															  })}
													</span>
												),
											}
										)}
									</div>
								</div>
								{!isGift ? (
									<div className="vinisto-user-orders__orders__order-body__item__prices">
										<div className="vinisto-user-orders__orders__order-body__item__prices__total">
											<span className="vinisto-user-orders__orders__order-body__item__prices__big-price">
												{getLocalizedPrice({
													price: totalPriceWithVAT,
													// @ts-expect-error possible "undefined" issue
													currency: priceCurrency,
												})}
											</span>
										</div>
										<div className="vinisto-user-orders__orders__order-body__item__prices__without-vat">
											{`${t({ id: 'basket.priceWithoutVAT' })} `}
											<span className="fw-bolder price-span">
												{getLocalizedPrice({
													price: totalPriceWithoutVAT,
													// @ts-expect-error possible "undefined" issue
													currency: priceCurrency,
												})}
											</span>
										</div>
									</div>
								) : (
									<div className="vinisto-user-orders__orders__order-body__item__prices">
										<div className="vinisto-user-orders__orders__order-body__item__prices__total">
											<span className="vinisto-user-orders__orders__order-body__item__prices__big-price">
												{`${t({ id: 'basket.price.free' })}`}
											</span>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default OrderItem;
