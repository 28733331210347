import {
	entriesIn,
	filter,
	find,
	first,
	flatten,
	forEach,
	get,
	isArray,
	isEmpty,
	isNumber,
	last,
	map,
	nth,
	omit,
	range,
	size,
	some,
	valuesIn,
} from 'lodash-es';
import { apiServiceInstance } from 'Services/ApiService';
import removeDiacritics from 'Helpers/removeDiacritics';
import { VinistoProductDllModelsApiBundleBundlesReturn } from 'vinisto_api_client/src/api-types/product-api';
import { UseQueryResult } from '@tanstack/react-query';

import {
	AvailableTagFilter,
	ICreateBundlePageQueriesProps,
	IFetchBundlePageProps,
} from './interfaces';
import {
	FILTER_TYPE,
	SPECIFICATION_TYPE_CHECK_BOX,
	SPECIFICATION_TYPE_COMBO_BOX,
	SPECIFICATION_TYPE_DECIMAL_NUMBER,
	SPECIFICATION_TYPE_DECIMAL_NUMBER_IMPERIAL,
	SPECIFICATION_TYPE_MULTI_COMBO_BOX,
	SPECIFICATION_TYPE_NUMBER,
	SPECIFICATION_TYPE_NUMBER_IMPERIAL,
	SPECIFICATION_TYPE_PRICE,
	SPECIFICATION_TYPE_TEXT,
	URL_PARAM_LIMIT_DEFAULT_VALUE,
} from './constants';

import { Specification } from '@/domain/specification/schema';
import { DEFAULT_BUNDLE_API_PARAMS } from '@/shared';

export const isTextSpecification = (specificationType: string) =>
	specificationType === SPECIFICATION_TYPE_TEXT;
export const isMultiComboBoxSpecification = (specificationType: string) =>
	specificationType === SPECIFICATION_TYPE_MULTI_COMBO_BOX;
export const isComboBoxSpecification = (specificationType: string) =>
	specificationType === SPECIFICATION_TYPE_COMBO_BOX;
export const isCheckboxSpecification = (specificationType: string) =>
	specificationType === SPECIFICATION_TYPE_CHECK_BOX;
export const isNumberSpecification = (specificationType: string) =>
	specificationType === SPECIFICATION_TYPE_NUMBER;
export const isNumberImperialSpecification = (specificationType: string) =>
	specificationType === SPECIFICATION_TYPE_NUMBER_IMPERIAL;
export const isDecimalNumberSpecification = (specificationType: string) =>
	specificationType === SPECIFICATION_TYPE_DECIMAL_NUMBER;
export const isDecimalNumberImperialSpecification = (
	specificationType: string
) => specificationType === SPECIFICATION_TYPE_DECIMAL_NUMBER_IMPERIAL;
export const isPriceSpecification = (specificationType: string) =>
	specificationType === SPECIFICATION_TYPE_PRICE;

export const fetchBundlePage = async (data: IFetchBundlePageProps) => {
	const page = get(data, 'page', 1);
	const limit = get(data, 'limit', 10);
	const categoryId = get(data, 'categoryId');
	const sortingColumn = get(data, 'sortingColumn');
	const isSortingDescending = get(data, 'isSortingDescending', false);
	const currency = get(data, 'currency');
	const filters = get(data, 'filters', []);
	const tagId = get(data, 'tagId');
	const countryOfSale = get(data, 'countryOfSale');

	if (!categoryId)
		throw {
			data: null,
			error: { error: new Error('Category ID is missing.') },
		};
	if (!currency)
		throw {
			data: null,
			error: { error: new Error('Currency is missing.') },
		};

	const offset = (page - 1) * limit;

	const bundlesData =
		await apiServiceInstance.post<VinistoProductDllModelsApiBundleBundlesReturn>(
			'product-api/bundles/GetBundles',
			{
				categoryId,
				sortingColumn,
				isSortingDescending,
				limit,
				offset,
				countryOfSale,
				currency,
				filters,
				tagId,
				isInImperialUnits: false,
				isCache: true,
				...DEFAULT_BUNDLE_API_PARAMS,
			},
			true
		);
	return bundlesData;
};

export const getBundlesCount = (paginatedBundleData: Record<any, any>[]) => {
	return get(
		size(paginatedBundleData) > 1
			? nth(paginatedBundleData, -2)
			: last(paginatedBundleData),
		'data.count',
		0
	);
};

export const mergeSpecificationsWithBundleFilters = (
	specifications: Specification[],
	filters: Record<string, any>[],
	exclude: {
		tags?: string[];
	} = { tags: [] }
) => {
	const tagFilters =
		filters?.find((spec) => spec?.filterType === FILTER_TYPE.TAG)?.tags ?? [];
	const supplierFilters =
		filters?.find((spec) => spec?.filterType === FILTER_TYPE.SUPPLIER)
			?.suppliers ?? [];
	const isInStockFilters =
		filters?.find((spec) => spec?.filterType === FILTER_TYPE.STOCK)
			?.isInStock ?? [];
	const isDiscountedFilters =
		filters?.find((spec) => spec?.filterType === FILTER_TYPE.DISCOUNT)
			?.isDicounted ?? [];

	const specificationFilters: Omit<Specification, 'specificationId'>[] = [];
	specifications.forEach((specification) => {
		// @ts-expect-error broken zod types?
		if (isTextSpecification(specification.type)) return;

		const bundleFilter = find(filters, {
			specificationDefinitionId: specification?.id ?? null,
		});

		const updatedSpecification = bundleFilter
			? { ...specification, values: bundleFilter }
			: specification;

		specificationFilters.push(
			omit(updatedSpecification, 'values.specificationDefinitionId')
		);
	});
	const result = {
		specificationFilters,
		tagFilters: tagFilters.filter(
			(tag: AvailableTagFilter) => !exclude.tags?.includes(tag.id)
		),
		supplierFilters,
		isInStockFilters,
		isDiscountedFilters,
	};

	return result;
};

export const getPageFromParam = (
	pageParam: any[]
): [number] | [number, number] => {
	if (!isArray(pageParam) && isNumber(pageParam)) return [pageParam];
	if (
		isEmpty(pageParam) ||
		some(pageParam, (pageNum) => isNaN(pageNum)) ||
		(first(pageParam) ?? 1) > (last(pageParam) ?? 2)
	)
		return [1];
	return pageParam as [number] | [number, number];
};

export const createBundlePageQueries = ({
	categoryId,
	sortingColumn,
	isSortingDescending = false,
	page,
	countryOfSale,
	currency,
	filters = [],
	tagId,
	limit = URL_PARAM_LIMIT_DEFAULT_VALUE,
	enabled = true,
	onError = () => null,
	onSuccess = () => null,
}: ICreateBundlePageQueriesProps) => {
	if (!categoryId || !page) return [];
	const pagesToFetch = range(first(page) ?? 1, (last(page) ?? 1) + 1);

	return pagesToFetch.map((pageNum: number) => ({
		queryKey: [
			'bundles',
			{
				categoryId,
				sortingColumn,
				isSortingDescending,
				filters,
				tagId,
				limit,
				countryOfSale,
				currency,
			},
			pageNum,
		],
		queryFn: () =>
			fetchBundlePage({
				page: pageNum,
				categoryId,
				sortingColumn,
				isSortingDescending,
				limit,
				filters,
				tagId,
				countryOfSale,
				currency,
			}),
		enabled,
		retry: 0,
		onError,
		onSuccess,
		keepPreviousData: true,
	}));
};

export const calculateBundlesToLoadMore = (
	bundlesCount: number,
	currentPage: number,
	limit = 10
) => {
	const bundlesLeft = bundlesCount - currentPage * limit;
	if (bundlesLeft < 1) return 0;
	if (bundlesLeft > limit) return limit;
	return bundlesLeft;
};

export const generateBundlesToShow = (
	categoryId: string,
	bundlesData: UseQueryResult<
		VinistoProductDllModelsApiBundleBundlesReturn,
		unknown
	>[],
	bundlesCount: number,
	currentPage: number,
	limit = 10,
	isLoading = false
) => {
	if (!categoryId) {
		return map(Array(limit), () => ({ isLoading: true }));
	}
	const bundles = filter(
		flatten(map(bundlesData, (page) => get(page, 'data.bundles', [])))
	);
	if (isLoading) {
		let bundlesLeft =
			bundlesCount - (currentPage === 1 ? 1 : currentPage - 1) * limit;
		if (bundlesLeft < 1) bundlesLeft = limit;
		if (bundlesLeft > limit) bundlesLeft = limit;
		return [
			...bundles,
			...map(Array(bundlesLeft), () => ({ isLoading: true })),
		];
	}
	return bundles;
};

export const generateRequestFilters = (
	specificationsWithBundleFilters: Specification[] | undefined,
	query: Record<any, any>,
	language: string
) => {
	const filters: Record<any, any>[] = [];
	forEach(specificationsWithBundleFilters, (specification) => {
		const specificationName = removeDiacritics(
			get(find(get(specification, 'name', []), { language }), 'value', '')
		);
		if (!specificationName) return;
		if (
			(isComboBoxSpecification(get(specification, 'specificationType', '')) ||
				isMultiComboBoxSpecification(
					get(specification, 'specificationType', '')
				)) &&
			get(query, specificationName)
		) {
			const selectedValues = map(
				isArray(get(query, specificationName))
					? get(query, specificationName)
					: [get(query, specificationName, '')?.toLowerCase()],
				(selectedValue) => {
					const allowedValue = filter(
						entriesIn(get(specification, 'allowedValues')),
						([value]: [string, Record<string, any>[]]) => {
							return (
								removeDiacritics(value).toLowerCase() ==
								selectedValue?.toLowerCase().replace(/\s+/g, '-')
							);
						}
					);
					const keys = map(allowedValue, (value) =>
						get(value, '[0]', '').toLowerCase()
					);
					return keys;
				}
			);
			filters.push({
				specificationName: get(specification, 'name', []),
				specificationDefinitionId: get(specification, 'id', ''),
				selectedValues: flatten(selectedValues),
				order: specification?.order ?? 0,
			});
		} else if (
			isCheckboxSpecification(get(specification, 'specificationType', '')) &&
			get(query, specificationName) !== undefined
		) {
			filters.push({
				specificationName: get(specification, 'name', []),
				specificationDefinitionId: get(specification, 'id', ''),
				isChecked: get(query, specificationName) ? true : false,
				order: specification?.order ?? 0,
			});
		} else if (
			get(query, specificationName) &&
			(isNumberSpecification(get(specification, 'specificationType', '')) ||
				isNumberImperialSpecification(
					get(specification, 'specificationType', '')
				) ||
				isDecimalNumberSpecification(
					get(specification, 'specificationType', '')
				) ||
				isDecimalNumberImperialSpecification(
					get(specification, 'specificationType', '')
				))
		) {
			const min = first(get(query, specificationName, []));
			const max = last(get(query, specificationName, []));
			if (isNumber(min) && isNumber(max)) {
				filters.push({
					specificationName: get(specification, 'name', []),
					specificationDefinitionId: get(specification, 'id', ''),
					unit: get(specification, 'unit', []),
					imperialUnit: get(specification, 'imperialUnit', []),
					min: first(get(query, specificationName, [])),
					max: last(get(query, specificationName, [])),
					isImperial: false,
					order: specification?.order ?? 0,
				});
			}
		} else if (
			isPriceSpecification(get(specification, 'specificationType', ''))
		) {
			const min = first(get(query, specificationName, []));
			const max = last(get(query, specificationName, []));
			if (isNumber(min) && isNumber(max)) {
				filters.push({
					specificationName: get(specification, 'name', []),
					specificationDefinitionId: get(specification, 'id', ''),
					min: first(get(query, specificationName, [])),
					max: last(get(query, specificationName, [])),
					currency: 1,
					order: specification?.order ?? 0,
				});
			}
		}
	});
	return filters;
};

export const translateFiltersQuery = (
	specificationsWithBundleFilters: Record<any, any>[],
	query: Record<any, any>,
	prevLanguageKey: string,
	languageKey: string
) => {
	const newQueries: Record<string, any> = {};
	forEach(specificationsWithBundleFilters, (specification) => {
		const specificationName = removeDiacritics(
			get(
				find(get(specification, 'name', []), {
					language: prevLanguageKey,
				}),
				'value'
			)
		);
		const newSpecificationName = removeDiacritics(
			get(
				find(get(specification, 'name', []), { language: languageKey }),
				'value'
			)
		);

		if (get(query, specificationName) || get(query, specificationName) === 0) {
			newQueries[specificationName] = undefined;
		}

		if (newSpecificationName) {
			if (
				isComboBoxSpecification(get(specification, 'specificationType')) ||
				isMultiComboBoxSpecification(get(specification, 'specificationType'))
			) {
				const newValue = filter(
					map(
						isArray(get(query, specificationName))
							? get(query, specificationName)
							: [get(query, specificationName)],
						(selectedValue) => {
							const allowedValue = find(
								valuesIn(get(specification, 'allowedValues')),
								(value: Record<any, any>[]) =>
									find(value, (allowedVal) => {
										if (
											get(allowedVal, 'language') === prevLanguageKey &&
											removeDiacritics(get(allowedVal, 'value')) ===
												selectedValue
										) {
											return true;
										}
										return false;
									})
							);
							const newValue = removeDiacritics(
								get(
									find(allowedValue, {
										language: languageKey,
									}),
									'value'
								)
							);
							if (newValue) {
								return newValue;
							}
						}
					)
				);
				if (!isEmpty(newValue)) {
					newQueries[newSpecificationName] = newValue;
				}
			} else {
				newQueries[newSpecificationName] = get(query, specificationName);
			}
		}
	});
	return newQueries;
};
