import { useContext } from 'react';
import cx from 'classnames';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { QuantityBoxTypes } from 'Components/QuantityBox/constants';
import getFlagSpecification from 'Helpers/getFlagSpecification';
import useLocalizedValue from 'Hooks/useLocalizedValue';
import usePreventDragRedirect from 'Hooks/use-prevent-drag-redirect';
import { LocalizationContext } from 'Services/LocalizationService';
import { WarehouseContext } from 'Services/WarehouseService';
import { DirectQuantityBox, StandardQuantityBox } from 'Components/QuantityBox';
import StockAvailability from 'Components/StockAvailability';
import quantityBoxStyles from 'Components/QuantityBox/styles.module.css';
import { useProductBasketCount } from 'Components/ProductBox/hooks';
import { DiscountPercentage } from 'vinisto_ui';

import ProductBasketCount from './Components/ProductBasketCount';
import BundleProducer from './Components/BundleProducer';
import BundleTag from './Components/BundleTag';
import ButtonAddToFavorites from './Components/ButtonAddToFavorites';
import ProductSpecifications from './Components/Specifications';
import ProductTitleAndImage from './Components/TitleAndImage';
import { CARD_TYPE, IProductBoxProps } from './interfaces';
import styles from './styles.module.css';
import useFavoriteItem from './Components/ButtonAddToFavorites/hook';

import { bundleAdapter } from '@/index';

const ProductBox = (props: IProductBoxProps) => {
	const localizationContext = useContext(LocalizationContext);
	const { activeCurrency } = localizationContext;
	const warehouseContext = useContext(WarehouseContext);

	const t = localizationContext.useFormatMessage();
	const getLocalizedValue = useLocalizedValue();
	const { title: currencyTitle } = localizationContext.activeCurrency;

	const showPercentageDiscount = props?.showPercentageDiscount ?? false;
	const bundleData = props?.bundleData ?? null;
	const bundleId = bundleData?.id ?? '';
	const bundleUrl = getLocalizedValue(bundleData?.url ?? []);
	const isTemporaryUnavailable = bundleData?.temporaryUnavailable ?? false;
	const isGift = bundleData?.isGift ?? false;

	const showAddToBasketBtn = props.showAddToBasketBtn ?? true;
	const showSpecifications = props.showSpecifications ?? true;
	const showProducer = props.showProducer ?? true;
	const showAddToFavoritesBtn =
		(props?.showAddToFavoritesBtn ?? true) &&
		!isTemporaryUnavailable &&
		!isGift;

	const specificationDetails = bundleData?.specificationDetails ?? [];

	const { shortVariety: producerName, component: flag } =
		getFlagSpecification(specificationDetails);

	const isLoading = props?.isLoading ?? false;
	const redirectToCrossSell = props?.redirectToCrossSell ?? true;

	const bundlePrices = bundleData
		? bundleAdapter.fromApi(bundleData, { currency: activeCurrency.currency })
				.bundlePrices
		: null;

	const { basePrice, discountedPrice, isDiscounted } = bundlePrices ?? {};

	const availableQuantity = [warehouseContext.getQuantity(bundleId)].filter(
		(x): x is number => x !== undefined
	);

	const { basketQuantityPopover } = useProductBasketCount(bundleData);

	const { handleAddItemToFavorites, handleRemoveItemFromFavorites } =
		useFavoriteItem(bundleId, {
			price:
				(isDiscounted
					? discountedPrice?.valueWithVat
					: basePrice?.valueWithVat) ?? 0,
			currency: currencyTitle,
			item_name: getLocalizedValue(bundleData?.name),
		});

	const { handleClick, handleMouseDown, preventDefaultEvent } =
		usePreventDragRedirect();

	return (
		<div
			className={cx('vinisto-wine__item', {
				loading: isLoading,
				[styles.cardMargin]: props.cardType === CARD_TYPE.SINGLE,
			})}
		>
			{isLoading ? (
				<ProductTitleAndImage
					isLoading
					bundleData={{}}
				/>
			) : (
				<>
					<Link
						className="vinisto-wine__item-overlay"
						to={`/${t({
							id: 'routes.product.route',
						})}/${bundleUrl}`}
						rel="nofollow"
						onDragStart={preventDefaultEvent}
						onMouseDown={handleMouseDown}
						onMouseUp={preventDefaultEvent}
						onClick={handleClick}
					/>
					<ProductBasketCount text={basketQuantityPopover} />
					<div className="vinisto-wine__img-labels">
						{(bundleData?.tagsDetail ?? []).map((tagDetail, index) => {
							return (
								<BundleTag
									{...{ tagDetail }}
									key={`bundle-${bundleId}-tag-${tagDetail?.id ?? index}`}
									isLink={false}
								/>
							);
						})}
					</div>
					<Link
						to={`/${t({
							id: 'routes.product.route',
						})}/${bundleUrl}`}
						tabIndex={-1}
						onDragStart={preventDefaultEvent}
						onMouseDown={handleMouseDown}
						onMouseUp={preventDefaultEvent}
						onClick={handleClick}
					>
						<ProductTitleAndImage bundleData={bundleData ?? {}} />
					</Link>

					{showAddToFavoritesBtn && (
						<ButtonAddToFavorites
							itemId={bundleData?.id ?? ''}
							addToFavorites={handleAddItemToFavorites}
							removeItemFromFavorites={handleRemoveItemFromFavorites}
							carouselType={props?.carouselType ?? ''}
						/>
					)}
				</>
			)}
			{showProducer && (
				<BundleProducer
					flag={flag}
					name={producerName}
					isLoading={isLoading}
					isLink={false}
				/>
			)}
			{showSpecifications && (
				<div className="vinisto-wine__specifications">
					<ProductSpecifications
						specifications={specificationDetails}
						isLoading={isLoading}
					/>
				</div>
			)}
			<div className="vinisto-wine__bottom-info">
				<div className="vinisto-wine__price">
					{isLoading ? (
						<Skeleton
							width="100%"
							inline
						/>
					) : (
						<>
							{isDiscounted && (
								<span className="vinisto-wine__price--original">
									{basePrice?.getFormatedValueWithVat()}
								</span>
							)}
							<div className={styles.discountPriceWrap}>
								{isDiscounted
									? discountedPrice?.getFormatedValueWithVat()
									: basePrice?.getFormatedValueWithVat()}
								{showPercentageDiscount && isDiscounted && (
									<DiscountPercentage
										discountedPriceWithVat={discountedPrice?.valueWithVat ?? 0}
										standardPriceWithVat={basePrice?.valueWithVat ?? 0}
										className={styles.discountPercentage}
									/>
								)}
							</div>
						</>
					)}
				</div>
				<div className="vinisto-wine__price--no-vat">
					{isLoading ? (
						<Skeleton
							width="100%"
							inline
						/>
					) : (
						t(
							{
								id: 'carousel.info.withoutVAT',
							},
							{
								priceWithCurrency: isDiscounted
									? discountedPrice?.getFormatedValue()
									: basePrice?.getFormatedValue(),
							}
						)
					)}
				</div>
				<div className="vinisto-wine__stock">
					<StockAvailability
						availableQuantity={availableQuantity}
						deliveryDate={warehouseContext.deliveryDate}
						isTemporaryUnavailable={isTemporaryUnavailable}
						fallback={<Skeleton width="80px" />}
					/>
				</div>
				{showAddToBasketBtn && !isTemporaryUnavailable && (
					<div
						className={cx(
							styles.aboveProductLink,
							styles.cta,
							'mt-3 vinisto-wine__qunatity-box-wrapper'
						)}
						onClick={(e) => e.stopPropagation()}
						role="button"
						tabIndex={0}
					>
						{props?.quantityBoxType === QuantityBoxTypes.STANDARD ? (
							<StandardQuantityBox
								bundle={bundleData}
								shouldRedirectToCrossSell={redirectToCrossSell}
								isLoading={isLoading}
								className={quantityBoxStyles.fullWidth}
								orderLimitation={bundleData?.orderLimitation}
								carouselType={props?.carouselType ?? ''}
							/>
						) : (
							<DirectQuantityBox
								bundle={bundleData}
								isLoading={isLoading}
								className={quantityBoxStyles.fullWidth}
								orderLimitation={bundleData?.orderLimitation}
								carouselType={props?.carouselType ?? ''}
							/>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

export default ProductBox;
