import { VinistoHelperDllEnumsCurrency } from 'vinisto_api_client/src/api-types/product-api';

import { getLocalizedCurrency } from '../get-localized-currency';

interface LocalizeCurrencyParams {
	price: number;
	currency:
		| VinistoHelperDllEnumsCurrency
		| keyof typeof VinistoHelperDllEnumsCurrency;
	displayCurrency?: boolean;
	decimalPlaces?: number;
	disableFormatting?: boolean;
}

// TODO Decimal places should specified in a default config based on currency
export const getLocalizedPrice = ({
	price,
	currency,
	displayCurrency = true,
	decimalPlaces = 0,
}: LocalizeCurrencyParams) => {
	if (price === null || price === undefined || !currency) {
		return '';
	}
	if (
		typeof window.Intl === 'undefined' ||
		!(currency in VinistoHelperDllEnumsCurrency)
	) {
		const decimalPlaces =
			currency === VinistoHelperDllEnumsCurrency.CZK ? 0 : 2;
		return `${price.toFixed(decimalPlaces)} ${getLocalizedCurrency(currency)}`;
	}
	if (currency === VinistoHelperDllEnumsCurrency.CZK) {
		return new Intl.NumberFormat('cs-CZ', {
			...(displayCurrency && { style: 'currency', currency: 'CZK' }),
			maximumFractionDigits: decimalPlaces,
		}).format(price);
	}

	if (currency === VinistoHelperDllEnumsCurrency.EUR) {
		return new Intl.NumberFormat('sk-SK', {
			...(displayCurrency && { style: 'currency', currency: 'EUR' }),
			maximumFractionDigits: 2,
		}).format(price);
	}

	if (currency === VinistoHelperDllEnumsCurrency.USD) {
		return new Intl.NumberFormat('en-US', {
			...(displayCurrency && { style: 'currency', currency: 'USD' }),
			maximumFractionDigits: decimalPlaces,
		}).format(price);
	}
	return '';
};
