import {
	lazy,
	Suspense,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import Config from 'Config';
import cx from 'classnames';
import { get, includes, indexOf, invoke, isUndefined, map } from 'lodash-es';
import Skeleton from 'react-loading-skeleton';
import { useMatch, useNavigate } from 'react-router-dom';
import { useQueryParam } from 'use-query-params';
import { DocumentHeaderAction } from 'Components/DocumentHeader/constants';
import ContainerFullWidth from 'Components/View/ContainerFullWidth';
import { GA_EVENT } from 'Hooks/useAnalytics/constants';
import { getPdfDocument, openPdf } from 'Pages/UserSection/Orders/helpers';
import useAnalytics from 'Hooks/useAnalytics';
import { DocumentHeaderContext } from 'Components/DocumentHeader/context';
import { PreloaderContext } from 'Components/Preloader/context';
import { apiServiceInstance } from 'Services/ApiService';
import { AuthenticationContext } from 'Services/AuthenticationService/context';
import { BasketContext } from 'Services/BasketService';
import { GoPayServiceContext } from 'Services/GoPayService';
import { LocalizationContext } from 'Services/LocalizationService';
import { NotificationsContext } from 'Services/NotificationService';
import { StorageContext } from 'Services/StorageService/context';
import { DeviceServiceContext } from 'Services/DeviceService';
import { ModalContext } from 'Components/Modal/context';
import { PHONE_NUMBER } from 'Components/Navbar/constants';
import { formatPhoneNumber } from 'Components/Navbar/helpers';
import OrderSummary from 'Pages/CartConfirmation/Components/OrderSummary';
import PaymentInfo from 'Pages/CartConfirmation/Components/PaymentInfo';
import OrderInfoHandover from 'Pages/CartConfirmation/Components/OrderInfoHandover';
import { TrackEvent } from 'Services/FacebookPixel';
import Loader from 'Components/View/Loader';
import { LOGIN_MODAL } from 'Components/Modal/constants';
const BigGreenOkIcon = lazy(() => import('Components/Icons/BigGreenOk'));
const PhoneCallIcon = lazy(() => import('Components/Icons/PhoneCall'));
const BigRedError = lazy(() => import('Components/Icons/BigRedError'));
const MailIcon = lazy(() => import('Components/Icons/MailIcon'));

import '../UserSection/Orders/styles.css';

import {
	ANALYTICS_SENT_STORAGE_KEY,
	GOPAY_PAYMENT_STATUS_ERROR,
	NOT_FOUND,
	OBJECT_PERMISSION_ERROR,
	ORDER_NOT_EXIST,
	ORDER_STATE,
	PAYMENT_STATE,
} from './constants';
import RegisterForm from './Components/RegisterForm';
import styles from './styles.module.css';

import './styles.css';
import {
	VinistoHelperDllEnumsOrderPaymentType,
	VinistoOrderDllModelsApiOrderOrder,
	VinistoOrderDllModelsApiOrderPayment,
	VinistoOrderDllModelsApiReturnDataOrderReturn,
} from '@/api-types/order-api';
import { VinistoGopayDllModelsApiPaymentReturn } from '@/api-types/services-api';

const CartConfirmation = () => {
	const goPayContext = useContext(GoPayServiceContext);
	const { togglePreloader } = useContext(PreloaderContext);
	const { handleShowErrorNotification } = useContext(NotificationsContext);
	const { dispatch } = useContext(DocumentHeaderContext);
	const { handleOnLoadBasket } = useContext(BasketContext);
	const { useFormatMessage } = useContext(LocalizationContext);
	const { isLoggedIn, anonymousUID, vinistoUser } = useContext(
		AuthenticationContext
	);
	const storageContext = useContext(StorageContext);
	const deviceContext = useContext(DeviceServiceContext);
	const { handleOpenModal } = useContext(ModalContext);

	const navigate = useNavigate();
	const { pushToDataLayer } = useAnalytics();
	const t = useFormatMessage();

	const anonymousUserId = anonymousUID?.anonymousUserId || '';
	const userLoginHash = vinistoUser.loginHash;

	const [orderStatus, setOrderStatus] = useState({
		loading: false,
		loaded: false,
		payment: {} as Partial<VinistoOrderDllModelsApiOrderPayment>,
		orderData: {} as Partial<VinistoOrderDllModelsApiOrderOrder>,
	});

	const paymentType = orderStatus.payment?.paymentType ?? null;
	const handleOnNavigateToOrders = useCallback(() => {
		navigate(
			`/${t({ id: 'routes.user-section.route' })}/${t({
				id: 'routes.user-section.orders.route',
			})}?id=${orderStatus.orderData.id}`
		);
	}, [navigate, orderStatus, t]);

	const match = useMatch(`/${t({ id: 'routes.cart.confirmation.route' })}`);
	const [goPayPaymentId] = useQueryParam('id');
	const [orderId] = useQueryParam('oid');

	const handleOnRetryPayment = () =>
		goPayContext.handleOnPayOnline(orderStatus?.orderData);

	const hasProformaAttached =
		orderStatus?.orderData?.invoice?.pdfProformaUrl ?? false;
	const hasProformaInvoiceAttached =
		orderStatus?.orderData?.invoice?.pdfVatInvoiceUrl ?? false;
	const hasInvoiceAttached = orderStatus?.orderData?.invoice?.pdfUrl ?? false;

	const handleOnClickInvoice = useCallback(
		(orderId: string, documentUrl: string) => async () => {
			try {
				const encodedPdfContent = await getPdfDocument(
					orderId,
					documentUrl,
					userLoginHash
				);
				if (!encodedPdfContent) throw new Error('No pdf content');
				openPdf(encodedPdfContent);
			} catch {
				handleShowErrorNotification('userSection.order.invoice.loadError');
			}
		},
		[]
	);

	useEffect(() => {
		dispatch({
			type: DocumentHeaderAction.setTitle,
			value: `${t(
				{ id: 'app.title.page' },
				{ title: `${t({ id: 'routes.cart.confirmation.name' })}` }
			)}`,
		});
	}, [dispatch, t]);

	const getOrderRequest = useCallback(() => {
		if (orderId && isLoggedIn) {
			apiServiceInstance
				.get<VinistoOrderDllModelsApiReturnDataOrderReturn>(
					'order-api/orders',
					true,
					`${orderId}`,
					[
						{
							key: 'UserLoginHash',
							value: userLoginHash,
						},
					]
				)
				.then((response) => {
					const countryOfSale = response?.order?.countryOfSale ?? 'CZ';
					const orderCurrency = response?.order?.orderCurrency ?? 'CZK';

					apiServiceInstance
						.delete(`basket-api/basket`, undefined, false, [
							{
								key: 'UserLoginHash',
								value: userLoginHash,
							},
							{
								key: 'CountryOfSale',
								value: countryOfSale,
							},
							{
								key: 'currency',
								value: orderCurrency,
							},
						])
						.then(() => {
							setOrderStatus({
								loading: false,
								loaded: true,
								payment: response.order?.payment ?? {},
								orderData: response.order ?? {},
							});
						})
						.finally(() => {
							togglePreloader(false);
							handleOnLoadBasket();
						});
				})
				.catch((error) => {
					setOrderStatus({
						loading: false,
						loaded: true,
						payment: {},
						orderData: {},
					});
					if (error.message === ORDER_NOT_EXIST) {
						navigate('/');
					}
				});
		} else if (orderId && !isLoggedIn) {
			apiServiceInstance
				.get<VinistoOrderDllModelsApiReturnDataOrderReturn>(
					`order-api/orders/${orderId}/anonymous/${anonymousUserId}`,
					true
				)
				.then((response) => {
					const countryOfSale = response?.order?.countryOfSale ?? 'CZ';
					const orderCurrency = response?.order?.orderCurrency ?? 'CZK';

					apiServiceInstance
						.delete(`basket-api/basket`, undefined, false, [
							{
								key: 'AnonymousUserId',
								value: anonymousUserId,
							},
							{
								key: 'CountryOfSale',
								value: countryOfSale,
							},
							{
								key: 'currency',
								value: orderCurrency,
							},
						])
						.then(() => {
							setOrderStatus({
								loading: false,
								loaded: true,
								payment: get(response, 'order.payment', {}),
								// @ts-expect-error TS error
								orderData: get(response, 'order', {}),
							});
						})
						.finally(() => {
							togglePreloader(false);
							handleOnLoadBasket();
						});
				})
				.catch((error) => {
					if (
						error.response?.error?.some(
							(error: any = {}) =>
								error.generalError === OBJECT_PERMISSION_ERROR
						)
					)
						setOrderStatus({
							loading: false,
							loaded: true,
							payment: {},
							orderData: {},
						});

					if (error.message === ORDER_NOT_EXIST) {
						navigate('/');
					}
				});
		}
	}, [
		anonymousUserId,
		handleOnLoadBasket,
		isLoggedIn,
		navigate,
		orderId,
		togglePreloader,
		userLoginHash,
	]);

	useEffect(() => {
		if (orderStatus.orderData?.state) {
			return;
		}

		setOrderStatus({
			loading: true,
			loaded: false,
			payment: {},
			orderData: {},
		});
		if (goPayPaymentId) {
			apiServiceInstance
				.get<VinistoGopayDllModelsApiPaymentReturn>(
					`services-api/gopay/${goPayPaymentId}`,
					true
				)
				.then((goPayResponse) => {
					if (get(goPayResponse, 'payment.state') === PAYMENT_STATE.PAID) {
						setOrderStatus({
							loading: false,
							loaded: false,
							// @ts-expect-error TS error
							payment: get(goPayResponse, 'payment', {}),
							orderData: {},
						});
					}

					const orderCurrency = goPayResponse?.payment?.currency ?? 'CZK';
					const countryOfSale = goPayResponse?.payment?.countryOfSale ?? 'CZK';

					const query = [
						{
							key: 'CountryOfSale',
							value: countryOfSale,
						},
						{
							key: 'currency',
							value: orderCurrency,
						},
					];

					if (isLoggedIn) {
						query.push({
							key: 'UserLoginHash',
							value: userLoginHash,
						});
					} else {
						query.push({
							key: 'AnonymousUserId',
							value: anonymousUserId,
						});
					}

					apiServiceInstance
						.delete(`basket-api/basket`, undefined, false, query)
						.then(() => {
							let getOrderRequest;
							if (isLoggedIn) {
								getOrderRequest = apiServiceInstance.get(
									`order-api/orders`,
									true,
									get(goPayResponse, 'payment.orderId', ''),
									[
										{
											key: 'UserLoginHash',
											value: userLoginHash,
										},
									]
								);
							} else {
								getOrderRequest = apiServiceInstance.get(
									`order-api/orders/${get(
										goPayResponse,
										'payment.orderId',
										''
									)}/anonymous/${anonymousUserId}`,
									true
								);
							}
							getOrderRequest
								.then((response: Record<any, any>) => {
									setOrderStatus({
										loading: false,
										loaded: true,
										// @ts-expect-error TS error
										payment: get(goPayResponse, 'payment', {}),
										orderData: get(response, 'order', {}),
									});
								})
								.catch(() => {
									setOrderStatus({
										loading: false,
										loaded: true,
										payment: {},
										orderData: {},
									});
								});
						})
						.finally(() => {
							togglePreloader(false);
							handleOnLoadBasket();
						});
				})
				.catch((error: Error) => {
					setOrderStatus({
						loading: false,
						loaded: true,
						payment: {},
						orderData: {},
					});
					if (
						error.message === ORDER_NOT_EXIST ||
						error.message === GOPAY_PAYMENT_STATUS_ERROR
					) {
						navigate('/');
					}
				});
		} else {
			getOrderRequest();
		}
	}, [
		match,
		goPayPaymentId,
		userLoginHash,
		isLoggedIn,
		orderId,
		orderStatus.orderData?.state,
		anonymousUserId,
		togglePreloader,
		handleOnLoadBasket,
		navigate,
		getOrderRequest,
	]);

	/** It may happen that order data are returned before invoices are generated.
	 * This code will attempt to retrieve the order data every second until
	 * the invoice is included, up to a maximum of 5 times */
	useEffect(() => {
		let counter = 0;
		const interval = setInterval(() => {
			if (counter >= 5 || orderStatus.orderData.invoice) {
				clearInterval(interval);
				return;
			}
			getOrderRequest();
			counter++;
		}, 1000);

		return () => {
			clearInterval(interval);
		};
	}, [getOrderRequest, orderStatus.orderData.invoice]);

	useEffect(() => {
		if (
			orderStatus.loaded === true &&
			!isLoggedIn &&
			!orderStatus?.orderData?.user?.anonymousId
		)
			handleOpenModal(LOGIN_MODAL, {
				showForgottenPasswordLink: false,
				showRegisterCta: false,
				showCloseButton: false,
			});
	}, [handleShowErrorNotification, isLoggedIn, orderStatus]);

	const storedAnalyticsSent: string[] = useMemo(
		() =>
			invoke(
				storageContext,
				'StorageService.getStorageItem',
				ANALYTICS_SENT_STORAGE_KEY,
				[]
			),
		[]
	);
	const [isAnalyticsSent, setIsAnalyticsSent] = useState<boolean>(
		!isUndefined(orderId) && indexOf(storedAnalyticsSent, orderId) !== NOT_FOUND
	);
	useEffect(() => {
		if (!get(orderStatus, 'loaded') || isAnalyticsSent) {
			return;
		}
		const orderData = get(orderStatus, 'orderData', {});
		const orderDataId = get(orderData, 'id', '');

		if (orderId !== orderDataId) {
			const isAlreadySent =
				indexOf(storedAnalyticsSent, orderDataId) !== NOT_FOUND;
			setIsAnalyticsSent(isAlreadySent);
			if (isAlreadySent) {
				return;
			}
		}

		const itemsGaData = map(
			get(orderData, 'orderItems', []),
			(item: Record<string, any>) => {
				return {
					item_id: get(item, 'bundle.id', ''),
					item_name: get(item, 'bundle.name', ''),
					price: get(item, 'bundle.price.value', 0),
					quantity: get(item, 'quantity', 0),
				};
			}
		);
		const itemsFbData = map(
			get(orderData, 'orderItems', []),
			(item: Record<string, any>) => {
				return {
					id: get(item, 'bundle.id', ''),
					quantity: get(item, 'quantity', 0),
				};
			}
		);

		const purchaseData = {
			email: get(orderData, 'billingAddress.email') ?? '',
			telephone: get(orderData, 'billingAddress.phone') ?? '',
			currency: get(orderData, 'orderCurrency') ?? '',
			transaction_id: get(orderData, 'orderNumber', ''),
			value:
				get(orderData, 'orderPrice', 0) -
				get(orderData, 'delivery.price.value', 0) -
				get(orderData, 'payment.price.value', 0),
			coupon: get(orderData, 'discountCoupon.description', ''),
			items: itemsGaData,
		};
		TrackEvent('track', 'Purchase', {
			content_type: 'product',
			contents: itemsFbData,
			value: Math.round((purchaseData.value + Number.EPSILON) * 100) / 100 || 0,
			currency: purchaseData.currency,
		});

		// TODO: Should be moved to new GA4 event before July 2024
		pushToDataLayer({
			event: GA_EVENT.PURCHASE,
			email: purchaseData.email,
			phone_number: purchaseData.telephone,
			ecommerce: {
				transaction_id: purchaseData.transaction_id,
				affiliation: get(Config, 'domainName', ''),
				value: purchaseData.value,
				shipping: get(orderData, 'delivery.price.value', 0),
				currency: purchaseData.currency,
				coupon: purchaseData.coupon,
				items: purchaseData.items,
			},
		});
		setIsAnalyticsSent(true);
		invoke(
			storageContext,
			'StorageService.setItem',
			ANALYTICS_SENT_STORAGE_KEY,
			[...storedAnalyticsSent, get(orderData, 'id')]
		);
	}, [orderStatus, isAnalyticsSent, orderId, storedAnalyticsSent]);

	const orderGiftItems = orderStatus?.orderData?.giftItems?.map((giftItem) => {
		return { bundle: giftItem.bundle, quantity: giftItem.quantity };
	});

	if (
		get(orderStatus, 'loading') ||
		!get(orderStatus, 'loaded') ||
		!get(orderStatus, 'orderData.orderNumber', false)
	) {
		return (
			<section id="content-wrapper">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<div className={styles.status}>
								<Skeleton
									width="100px"
									height="100px"
									count={1}
								/>
								<Skeleton
									width="330px"
									height="25px"
									count={1}
									className="vinisto-card__heading vinisto-card__heading--cart-confirmation vinisto-color-success"
								/>
							</div>
						</div>
					</div>
				</div>

				<div className="container">
					<div className="row">
						<div className="col-12">
							<div className="vinisto-card">
								<div className="vinisto-confirmation__heading-wrap">
									<Skeleton
										width="300px"
										height="25px"
										count={1}
										className="vinisto-card__heading"
										style={{
											maxWidth: '100%',
										}}
									/>
									<div className="vinisto-confirmation__share desktop-only">
										<Skeleton
											width="230px"
											height="16px"
											count={1}
											className="vinisto-font-18"
											style={{
												maxWidth: '100%',
											}}
										/>
										<Skeleton
											width="20px"
											height="20px"
											count={1}
											className="vinisto-font-18"
											style={{
												maxWidth: '100%',
											}}
										/>
										<Skeleton
											width="20px"
											height="20px"
											count={1}
											className="vinisto-font-18"
											style={{
												maxWidth: '100%',
											}}
										/>
										<Skeleton
											width="20px"
											height="20px"
											count={1}
											className="vinisto-font-18"
											style={{
												maxWidth: '100%',
											}}
										/>
									</div>
								</div>
								<p className="mb-0 mt-2 vinisto-font-18">
									<Skeleton
										width="550px"
										height="16px"
										count={1}
										className="vinisto-font-18"
										style={{
											maxWidth: '100%',
										}}
									/>
								</p>
							</div>
						</div>
					</div>
				</div>

				<div className="container">
					<div className="row">
						<div className="col-12">
							<div className="vinisto-card">
								<Skeleton
									width="245px"
									height="25px"
									count={1}
									className="vinisto-card__heading"
									style={{
										maxWidth: '100%',
									}}
								/>
								<Skeleton
									width="490px"
									height="16px"
									count={1}
									className="mb-0 mt-2 vinisto-font-18"
									style={{
										maxWidth: '100%',
									}}
								/>
								<Skeleton
									width="400px"
									height="16px"
									count={1}
									className="mb-0 vinisto-font-18"
									style={{
										maxWidth: '100%',
									}}
								/>
							</div>
						</div>
					</div>
				</div>

				<div className="container">
					<div className="row">
						<div className="col-12">
							<div className="vinisto-card">
								<Skeleton
									width="245px"
									height="25px"
									count={1}
									className="vinisto-card__heading"
									style={{
										maxWidth: '100%',
									}}
								/>
								<Skeleton
									width="950px"
									height="16px"
									count={1}
									className="mb-0 mt-2 vinisto-font-18"
									style={{
										maxWidth: '100%',
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}

	if (
		includes(get(orderStatus, 'orderData.state'), ORDER_STATE.PAID) ||
		includes(get(orderStatus, 'payment.state'), PAYMENT_STATE.PAID)
	) {
		return (
			<section id="content-wrapper">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<div className={styles.status}>
								<Suspense fallback={<Loader blank />}>
									<BigGreenOkIcon
										id="cart-onfirmation-ok-ico"
										alt=""
										title=""
										className=""
									/>
								</Suspense>
								<h1 className="vinisto-card__heading vinisto-card__heading--cart-confirmation vinisto-color-success">
									{t({
										id: 'orderConfirmation.success.title',
									})}
								</h1>
								<Suspense fallback={<Loader blank />}>
									<BigGreenOkIcon
										id="cart-onfirmation-ok-ico"
										alt=""
										title=""
										className="invisible"
									/>
								</Suspense>
							</div>
						</div>
					</div>
				</div>

				<div className="container">
					<div className="row">
						<div className="col-12">
							<div className="vinisto-card">
								<p className="mb-0 mt-2 vinisto-font-18">
									{t(
										{
											id: 'orderConfirmation.success.text.orderAccepted',
										},
										{
											orderNumber: (
												<span className="fw-bolder">
													{t(
														{
															id: 'orderConfirmation.success.text.orderId',
														},
														{
															value: get(
																orderStatus,
																'orderData.orderNumber',
																''
															),
														}
													)}
												</span>
											),
										}
									)}
								</p>
								<p className="mb-0 vinisto-font-18">
									{t(
										{
											id: 'orderConfirmation.success.text.orderState',
										},
										{
											highlightedFollow: (
												<span className="fw-bolder">
													{t({
														id: 'orderConfirmation.success.text.orderState.follow',
													})}
												</span>
											),
											highlightedInvoice: (
												<span className="fw-bolder">
													{t({
														id: 'orderConfirmation.success.text.orderState.invoice',
													})}
												</span>
											),
										}
									)}
								</p>
							</div>
						</div>
					</div>
				</div>
				{isLoggedIn && (
					<div className="container">
						<div className="row">
							<div className="col-12 vinisto-confirmation__ctas">
								<button
									onClick={handleOnNavigateToOrders}
									className="vinisto-btn vinisto-bg vinisto-font-18"
								>
									{t({
										id: 'orderConfirmation.success.followOrderLink',
									})}
								</button>
							</div>
						</div>
					</div>
				)}

				<div className="container">
					<div className="row">
						<div className="col-12">
							<div className="vinisto-card">
								<h1 className="vinisto-card__heading">
									{t({
										id: 'orderConfirmation.success.sms.title',
									})}
								</h1>
								<p className="mb-0 mt-2 vinisto-font-18">
									{t({
										id: 'orderConfirmation.success.sms.text',
									})}
								</p>
							</div>
						</div>
					</div>
				</div>

				{!isLoggedIn && (
					<div className="container">
						<div className="row">
							<div className="col-12">
								<RegisterForm
									email={orderStatus.orderData.billingAddress?.email ?? ''}
								/>
							</div>
						</div>
					</div>
				)}
				<OrderSummary
					orderItems={orderStatus.orderData.orderItems}
					delivery={orderStatus.orderData.delivery}
					orderGiftItems={orderGiftItems}
					payment={orderStatus.orderData.payment}
					billingAddress={orderStatus.orderData.billingAddress}
					discountCoupons={orderStatus.orderData.discountCoupons}
				/>
			</section>
		);
	}

	if (
		includes(get(orderStatus, 'orderData.state'), ORDER_STATE.CREATED) &&
		(paymentType === VinistoHelperDllEnumsOrderPaymentType.BY_HANDOVER ||
			paymentType === VinistoHelperDllEnumsOrderPaymentType.CASH ||
			paymentType === VinistoHelperDllEnumsOrderPaymentType.BANK_TRANSFER)
	) {
		return (
			<section id="content-wrapper">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<div className={styles.status}>
								<Suspense fallback={<Loader blank />}>
									<BigGreenOkIcon
										id="cart-onfirmation-ok-ico"
										alt=""
										title=""
										className={styles.icon}
									/>
								</Suspense>
								<h1 className={styles.statusHeading}>
									{t({
										id: 'orderConfirmation.created.title',
									})}
								</h1>
								<Suspense fallback={<Loader blank />}>
									<BigGreenOkIcon
										id="cart-onfirmation-ok-ico"
										alt=""
										title=""
										className={styles.icon}
									/>
								</Suspense>
							</div>
						</div>
					</div>
				</div>

				{paymentType ===
					VinistoHelperDllEnumsOrderPaymentType.BANK_TRANSFER && (
					<PaymentInfo
						orderId={orderStatus.orderData.id ?? ''}
						orderNumber={orderStatus.orderData.orderNumber ?? ''}
						orderPrice={orderStatus.orderData.orderPriceWithVat ?? 0}
					/>
				)}

				{paymentType === VinistoHelperDllEnumsOrderPaymentType.BY_HANDOVER &&
					orderStatus.orderData.orderNumber && (
						<OrderInfoHandover
							orderNumber={orderStatus.orderData.orderNumber}
						/>
					)}

				{!isLoggedIn && (
					<div className="container">
						<div className="row">
							<div className="col-12">
								<RegisterForm
									email={orderStatus.orderData.billingAddress?.email ?? ''}
								/>
							</div>
						</div>
					</div>
				)}
				{isLoggedIn && (
					<div className="container">
						<div className="row">
							<div className="col-12 vinisto-confirmation__ctas">
								<button
									onClick={handleOnNavigateToOrders}
									className={cx(
										'vinisto-btn vinisto-bg-green',
										styles.checkStateButton
									)}
								>
									{t({
										id: 'orderConfirmation.success.followOrderLink',
									})}
								</button>
								<div className="d-inline-block ms-0 ms-xxl-3">
									{hasProformaAttached && (
										<button
											className="vinisto-btn bg-transparent color-primary vinisto-font-18 px-4"
											onClick={handleOnClickInvoice(
												get(orderStatus, 'orderData.id', ''),
												get(orderStatus, 'orderData.invoice.pdfProformaUrl', '')
											)}
										>
											{t(
												{
													id: 'orderConfirmation.success.proformaLink',
												},
												{
													value: get(orderStatus, 'orderData.orderNumber', ''),
												}
											)}
										</button>
									)}

									{hasProformaInvoiceAttached && (
										<button
											className="vinisto-btn bg-transparent color-primary vinisto-font-18 px-3"
											onClick={handleOnClickInvoice(
												get(orderStatus, 'orderData.id', ''),
												get(
													orderStatus,
													'orderData.invoice.pdfVatInvoiceUrl',
													''
												)
											)}
										>
											{t(
												{
													id: 'orderConfirmation.success.proformaInvoiceLink',
												},
												{
													value: get(orderStatus, 'orderData.orderNumber', ''),
												}
											)}
										</button>
									)}

									{hasInvoiceAttached && (
										<button
											className="vinisto-btn bg-transparent color-primary vinisto-font-18 px-3"
											onClick={handleOnClickInvoice(
												get(orderStatus, 'orderData.id', ''),
												get(orderStatus, 'orderData.invoice.pdfUrl', '')
											)}
										>
											{t(
												{
													id: 'orderConfirmation.success.invoiceLink',
												},
												{
													value: get(orderStatus, 'orderData.orderNumber', ''),
												}
											)}
										</button>
									)}
								</div>
							</div>
						</div>
					</div>
				)}
				<OrderSummary
					orderItems={orderStatus.orderData.orderItems}
					orderGiftItems={orderGiftItems}
					delivery={orderStatus.orderData.delivery}
					payment={orderStatus.orderData.payment}
					billingAddress={orderStatus.orderData.billingAddress}
					discountCoupons={orderStatus.orderData.discountCoupons}
				/>
			</section>
		);
	}

	return (
		<section id="content-wrapper">
			<div className="container">
				<div className="row">
					<div className="col-12">
						<div className="vinisto-confirmation__status">
							<Suspense fallback={<Loader blank />}>
								<BigRedError
									id="cart-onfirmation-err-ico"
									alt=""
									title=""
									className=""
								/>
							</Suspense>
							<h1 className="vinisto-card__heading vinisto-card__heading--cart-confirmation vinisto-color-error text-center">
								{t({ id: 'orderConfirmation.fail.title' })}
							</h1>
							<Suspense fallback={<Loader blank />}>
								<BigRedError
									id="cart-onfirmation-err-ico"
									alt=""
									title=""
									className={
										deviceContext.isTablet || deviceContext.isMobile
											? 'invisible'
											: ''
									}
								/>
							</Suspense>
						</div>
						<ContainerFullWidth>
							<h2 className="vinisto-heading underline mt-2 mt-xxl-4">
								{t({
									id: 'cartShippingPayment.info',
								})}
							</h2>
							<div className={styles.info}>
								<p className={styles.infoText}>
									{t(
										{
											id: 'orderConfirmation.fail.info',
										},
										{
											orderNumber: (
												<span className={styles.boldText}>
													{t(
														{
															id: 'orderConfirmation.fail.info.orderNumber',
														},
														{
															value: orderStatus.orderData.orderNumber,
														}
													)}
												</span>
											),
											error: (
												<span className={styles.errorText}>
													{t({
														id: 'orderConfirmation.fail.info.error',
													})}
												</span>
											),
											noExpedition: (
												<span className={styles.errorText}>
													{t({
														id: 'orderConfirmation.fail.info.noExpedition',
													})}
												</span>
											),
										}
									)}
								</p>
								<div className="py-3">
									<button
										onClick={handleOnRetryPayment}
										className="vinisto-btn vinisto-bg-green vinisto-payment-error-btn"
									>
										{t({
											id: 'orderConfirmation.fail.repeat.btn',
										})}
									</button>
								</div>
								<p className={styles.infoText}>
									{t({
										id: 'orderConfirmation.fail.info.moreInfo',
									})}
								</p>
								<div className={styles.contactUs}>
									<a
										href={`tel:${PHONE_NUMBER}`}
										className={styles.linkTo}
									>
										<Suspense fallback={<Loader blank />}>
											<PhoneCallIcon className={styles.contactUsPhoneIcon} />
										</Suspense>
										{formatPhoneNumber(PHONE_NUMBER)}{' '}
										{t({
											id: 'orderConfirmation.fail.info.opening',
										})}
									</a>
									<a
										href={`mailto:${Config.market.supportEmail}`}
										className={styles.linkTo}
									>
										<Suspense fallback={<Loader blank />}>
											<MailIcon className={styles.contactUsMailIcon} />
										</Suspense>
										{Config.market.supportEmail}
									</a>
								</div>
							</div>
						</ContainerFullWidth>
						<OrderSummary
							orderItems={orderStatus.orderData.orderItems}
							orderGiftItems={orderGiftItems}
							delivery={orderStatus.orderData.delivery}
							payment={orderStatus.orderData.payment}
							billingAddress={orderStatus.orderData.billingAddress}
							discountCoupons={orderStatus.orderData.discountCoupons}
						/>
					</div>
				</div>
			</div>
		</section>
	);
};

export default CartConfirmation;
