import { TEST_IDS } from 'Constants/test-ids';
import { DeliveryMethodsPlacement } from 'Components/DeliveryMethods/types';
import { SKELETONS_NUM_DELIVERIES } from 'Pages/CartShippingPayment/constants';
import { useContext, useState } from 'react';
import { OrderContext } from 'Services/OrderService/context';
import { LocalizationContext } from 'Services/LocalizationService';
import { dayjsInstance as dayjs } from 'vinisto_shared';
import useTodayTomorrowDate from 'Hooks/useTodayTomorrowDate/useTodayTomorrowDate';
import { getLocalizedPrice } from 'vinisto_shared/src/price/get-localized-price';

import DeliveryItem from '../DeliveryItem';
import ShippingItem from '../ShippingItem';

import {
	VinistoHelperDllEnumsOrderDeliveryType,
	VinistoOrderDllModelsApiDeliveryDelivery,
} from '@/api-types/warehouse-api';

const DeliveryList = ({
	deliveries,
	isDeliveriesLoading,
}: {
	deliveries: VinistoOrderDllModelsApiDeliveryDelivery[];
	isDeliveriesLoading: boolean;
}) => {
	const orderContext = useContext(OrderContext);
	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();
	const handleReset = () => orderContext.setDeliveryMethod(null);

	const pickups = deliveries.filter(
		(delivery) =>
			delivery.deliveryType === VinistoHelperDllEnumsOrderDeliveryType.PICKUP
	);

	const [shouldShowPickupGroup, setShouldShowPickupGroup] = useState(false);

	const pickupsSortedByPickupTime = pickups.sort((a, b) => {
		const aPickupDate = +dayjs(
			`${a.deliveryTimeRange?.deliveryDate} ${a.deliveryTimeRange?.timeFrom}`,
			'YYYY-MM-DD hh-mm-ss'
		);
		const bPickupDate = +dayjs(
			`${b.deliveryTimeRange?.deliveryDate} ${b.deliveryTimeRange?.timeFrom}`,
			'YYYY-MM-DD hh-mm-ss'
		);

		return aPickupDate - bPickupDate;
	});

	const pickupsSortedByPickupPrice = pickups.sort((a, b) => {
		const aPickupPrice = a.prices[0].valueWithVat ?? 0;
		const bPickupPrice = b.prices[0].valueWithVat ?? 0;

		return aPickupPrice - bPickupPrice;
	});

	const soonestAvailablePickup = pickupsSortedByPickupTime[0];

	const cheapestPickupPrice = pickupsSortedByPickupPrice[0]?.prices[0];

	const cheapestPickupPriceAsString =
		Math.round(cheapestPickupPrice?.valueWithVat ?? 0) === 0
			? t({ id: 'basket.price.free' })
			: !cheapestPickupPrice?.valueWithVat
			? ''
			: getLocalizedPrice({
					price: cheapestPickupPrice.valueWithVat,
					// @ts-expect-error fix that later
					currency: cheapestPickupPrice.currency,
			  });

	const getDateLabel = useTodayTomorrowDate(soonestAvailablePickup);

	const formattedDate = getDateLabel(
		soonestAvailablePickup?.deliveryTimeRange?.deliveryDate ?? '',
		true
	);

	const otherDeliveries = deliveries.filter(
		(delivery) =>
			delivery.deliveryType !== VinistoHelperDllEnumsOrderDeliveryType.PICKUP
	);

	const isChildItemSelected = pickups.some(
		(pickup) => pickup.id === orderContext.deliveryMethod?.id
	);

	if (isDeliveriesLoading) {
		return (
			<div>
				{Array.from({ length: SKELETONS_NUM_DELIVERIES }).map((_, index) => (
					<DeliveryItem
						key={index}
						isLoading={true}
						placement={DeliveryMethodsPlacement.CHECKOUT}
					/>
				))}
			</div>
		);
	}

	return (
		<div>
			{otherDeliveries?.map((delivery, index) => (
				<DeliveryItem
					key={delivery?.id ?? index}
					delivery={delivery}
					dataTestid={TEST_IDS.BASKET_SHIPPING_TYPE + '_' + delivery?.id}
					placement={DeliveryMethodsPlacement.CHECKOUT}
				/>
			))}
			{pickups.length > 0 && (
				<ShippingItem
					onClick={() => setShouldShowPickupGroup(!shouldShowPickupGroup)}
					isLoading={false}
					isSelected={isChildItemSelected || shouldShowPickupGroup}
					title={`${t({ id: 'deliveryOptions.personal.parentTitle' })}`}
					titleContent={
						<div>{t({ id: 'deliveryOptions.personal.parentTitle' })}</div>
					}
					priceContent={
						isChildItemSelected
							? null
							: `cena od: ${cheapestPickupPriceAsString}`
					}
					dateContent={
						isChildItemSelected
							? null
							: t(
									{ id: 'bundleAvailability.availableDeliveryDateFrom' },
									{ day: `${formattedDate}` }
							  )
					}
					selectable={true}
					handleReset={() => {
						handleReset();
						setShouldShowPickupGroup(false);
					}}
					placement={DeliveryMethodsPlacement.CHECKOUT}
				/>
			)}
			{(isChildItemSelected || shouldShowPickupGroup) &&
				pickups?.map((delivery, index) => (
					<DeliveryItem
						key={delivery?.id ?? index}
						delivery={delivery}
						dataTestid={TEST_IDS.BASKET_SHIPPING_TYPE + '_' + delivery?.id}
						placement={DeliveryMethodsPlacement.CHECKOUT}
						showIcons={false}
						className={'ms-4'}
					/>
				))}
		</div>
	);
};

export default DeliveryList;
