import { BUNDLES_URI, AUTOCOMPLETE_LIMIT } from '../constants';

import api from '@/api';
import {
	BundlesGetBundleGiftsDetailParams,
	BundlesGetIdenticalBundlesDetailParams,
	VinistoGiftsDllModelsApiGiftRuleGiftRulesReturn, VinistoProductDllModelsApiBundleBundleAssignParameters,
	VinistoProductDllModelsApiBundleBundleCreateParameters,
	VinistoProductDllModelsApiBundleBundleReturn,
	VinistoProductDllModelsApiBundleBundleSaleOverParameters,
	VinistoProductDllModelsApiBundleBundleUnassignParameters,
	VinistoProductDllModelsApiBundleBundlesGetParameters,
	VinistoProductDllModelsApiBundleBundlesReturn,
	VinistoProductDllModelsApiFullSearchFullSearchSeparatedReturn
} from '@/api-types/product-api';
import { bundleAdapter, giftRuleAdapter } from '@/index';
import { DEFAULT_BUNDLE_API_PARAMS } from '@/shared';

const getAutocompleteBundles = (value: string) =>
	api.get<VinistoProductDllModelsApiFullSearchFullSearchSeparatedReturn>(
		`${BUNDLES_URI}/GetAutocompleteNames`,
		{
			searchingNameString: value,
			limit: AUTOCOMPLETE_LIMIT,
			...DEFAULT_BUNDLE_API_PARAMS
		}
	);

const getBundleById = async (
	id: string,
	params?: VinistoProductDllModelsApiBundleBundlesGetParameters
) => {
	const res = await api.get<VinistoProductDllModelsApiBundleBundleReturn>(
		`${BUNDLES_URI}/${id}`,
		params
	);

	if (res.bundle === null || res.bundle === undefined)
		throw new Error('No bundle data in response');

	return bundleAdapter.fromApi(res.bundle, { currency: params?.currency });
};

const getIdenticalBundles = async (
	bundleId: string,
	params?: Omit<BundlesGetIdenticalBundlesDetailParams, "bundleId">
) => {
	const res = await api.get<VinistoProductDllModelsApiBundleBundlesReturn>(
		`${BUNDLES_URI}/${bundleId}/GetIdenticalBundles`,
		params
	);

	if (res.bundles === null || res.bundles === undefined)
		throw new Error('No bundles data in response');

	// TODO temporary disable adapter here until BundleDetail data mapping is unified
	// return res.bundles.map((bundle) => bundleAdapter.fromApi(bundle, { currency: params?.currency }));
	return res.bundles;
};

const getBundleGifts = async (bundleId: string, params: Omit<BundlesGetBundleGiftsDetailParams, "bundleId">) => {
	const res = await api.get<VinistoGiftsDllModelsApiGiftRuleGiftRulesReturn>(
		`${BUNDLES_URI}/${bundleId}/get-bundle-gifts`, params
	);

	if (res.giftRules === null || res.giftRules === undefined)
		throw new Error('No gifts data in response');

	return res.giftRules.map((giftRule) => giftRuleAdapter.fromApi(giftRule));
};

const createBundleSet = async (
	params: VinistoProductDllModelsApiBundleBundleCreateParameters
) =>
	api.post<VinistoProductDllModelsApiBundleBundleReturn>(
		`${BUNDLES_URI}`,
		undefined,
		params
	);

const addBundleToSet = async (
	bundleSetId: string,
	params: VinistoProductDllModelsApiBundleBundleAssignParameters
) =>
	api.post<VinistoProductDllModelsApiBundleBundleReturn>(
		`${BUNDLES_URI}/${bundleSetId}/bundles`,
		undefined,
		params
	);

const removeBundleFromSet = async (
	bundleSetId: string,
	params: VinistoProductDllModelsApiBundleBundleUnassignParameters
) => {
	const { itemId, amount, price, userLoginHash } = params;
	return api.delete(`${BUNDLES_URI}/${bundleSetId}/bundles`, undefined, {
		itemId,
		amount,
		price,
		userLoginHash,
	});
};

const setIsSellOver = async (
	bundleId: string,
	req: VinistoProductDllModelsApiBundleBundleSaleOverParameters
) => {
	const res = await api.put(
		`${BUNDLES_URI}/${bundleId}/set-sell-over`,
		undefined,
		req
	);

	return res;
};

const BundleService = {
	getAutocompleteBundles,
	getBundleById,
	getIdenticalBundles,
	getBundleGifts,
	createBundleSet,
	addBundleToSet,
	removeBundleFromSet,
	setIsSellOver,
};

export default BundleService;
