import { filter, first, flatten, get, last, map, range } from 'lodash-es';
import { apiServiceInstance } from 'Services/ApiService';

import {
	ICreateBundlePageQueriesProps,
	IFetchBundlePageProps,
} from './interfaces';
import { URL_PARAM_LIMIT_DEFAULT_VALUE } from './constants';

import { DEFAULT_BUNDLE_API_PARAMS } from '@/shared';
import { VinistoProductDllModelsApiBundleBundlesReturn } from '@/api-types/product-api';

export {
	getBundlesCount,
	mergeSpecificationsWithBundleFilters,
	getPageFromParam,
	calculateBundlesToLoadMore,
	generateRequestFilters,
	translateFiltersQuery,
} from 'Pages/Category/Components/CategoryBundlesWithFilters/helpers';

export const fetchBundlePage = async (data: IFetchBundlePageProps) => {
	const page = get(data, 'page', 1);
	const limit = get(data, 'limit', 10);
	const sortingColumn = get(data, 'sortingColumn');
	const isSortingDescending = get(data, 'isSortingDescending', false);
	const currency = get(data, 'currency');
	const filters = get(data, 'filters', []);
	const categoryId = get(data, 'categoryId');
	const tagId = get(data, 'tagId');
	const countryOfSale = get(data, 'countryOfSale');

	if (!currency)
		throw {
			data: null,
			error: { error: new Error('Currency is missing.') },
		};

	const offset = (page - 1) * limit;

	const bundlesData =
		await apiServiceInstance.post<VinistoProductDllModelsApiBundleBundlesReturn>(
			'product-api/bundles/GetBundles',
			{
				sortingColumn,
				isSortingDescending,
				limit,
				offset,
				countryOfSale,
				currency,
				filters,
				categoryId,
				tagId,
				isInImperialUnits: false,
				isCache: true,
				...DEFAULT_BUNDLE_API_PARAMS,
			},
			true
		);
	return bundlesData;
};

export const createBundlePageQueries = ({
	sortingColumn,
	isSortingDescending = false,
	page,
	countryOfSale,
	currency,
	filters = [],
	categoryId,
	tagId,
	limit = URL_PARAM_LIMIT_DEFAULT_VALUE,
	enabled = true,
	onError = () => {},
	onSuccess = () => {},
}: ICreateBundlePageQueriesProps) => {
	const pagesToFetch = range(first(page) ?? 1, (last(page) ?? 1) + 1);
	return map(pagesToFetch, (pageNum: number) => ({
		queryKey: [
			'bundles',
			{
				sortingColumn,
				isSortingDescending,
				filters,
				categoryId,
				tagId,
				limit,
				countryOfSale,
				currency,
			},
			pageNum,
		],
		queryFn: () =>
			fetchBundlePage({
				page: pageNum,
				sortingColumn,
				isSortingDescending,
				limit,
				filters,
				categoryId,
				tagId,
				countryOfSale,
				currency,
			}),
		enabled,
		retry: 0,
		onError,
		onSuccess,
		keepPreviousData: true,
	}));
};

export const generateBundlesToShow = (
	bundlesData: Record<any, any>[],
	bundlesCount: number,
	currentPage: number,
	limit = 10,
	isLoading = false
) => {
	const bundles = filter(
		flatten(map(bundlesData, (page) => get(page, 'data.bundles', [])))
	);
	if (isLoading) {
		let bundlesLeft =
			bundlesCount - (currentPage === 1 ? 1 : currentPage - 1) * limit;
		if (bundlesLeft < 1) bundlesLeft = limit;
		if (bundlesLeft > limit) bundlesLeft = limit;
		return [
			...bundles,
			...map(Array(bundlesLeft), () => ({ isLoading: true })),
		];
	}
	return bundles;
};
