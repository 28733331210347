import { useCallback, useContext, useMemo } from 'react';
import { isNumber, last } from 'lodash-es';
import { useQueries, useQuery, UseQueryOptions } from '@tanstack/react-query';
import {
	BundleSorting,
	IBundlesWithFiltersProviderProps,
} from 'Pages/Category/Components/CategoryBundlesWithFilters/interfaces';
import removeDiacritics from 'Helpers/removeDiacritics';
import { useBundleQueries } from 'Hooks/Queries/useBundleQueries';
import useCategoryView from 'Hooks/useCategoryView';
import useURLParams from 'Hooks/useURLParams';
import { NavbarContext } from 'Components/Navbar/context';
import { BundlesWithFiltersContext } from 'Pages/Category/Components/CategoryBundlesWithFilters/context';
import { LocalizationContext } from 'Services/LocalizationService';
import { WarehouseContext } from 'Services/WarehouseService';
import {
	VinistoHelperDllEnumsTagSortableColumns,
	VinistoProductDllModelsApiBundleBundlesReturn,
} from 'vinisto_api_client/src/api-types/product-api';
import useLocalizedValue from 'Hooks/useLocalizedValue';

import {
	calculateBundlesToLoadMore,
	createBundlePageQueries,
	generateBundlesToShow,
	generateRequestFilters,
	getBundlesCount,
	getPageFromParam,
	mergeSpecificationsWithBundleFilters,
} from './helpers';
import {
	FILTER_CODE,
	PRICE_SPECIFICATION_ID,
	SALE_TAG_ID,
	SORTING,
	SORTING_DEFAULT,
	URL_PARAM_LIMIT,
	URL_PARAM_LIMIT_DEFAULT_VALUE,
	URL_PARAM_PAGE,
} from './constants';

import SpecificationService from '@/product-service/specification';
import TagService from '@/product-service/tag';
import { VinistoHelperDllEnumsCurrency } from '@/api-types/warehouse-api';

const { getAll } = TagService;
const { getAllSpecifications } = SpecificationService;

const BundlesWithFiltersProvider = (
	props: IBundlesWithFiltersProviderProps
) => {
	const localizationContext = useContext(LocalizationContext);
	const {
		countryOfSale,
		activeCurrency: { currency },
		convertEURtoCZK,
	} = localizationContext;
	const warehouseContext = useContext(WarehouseContext);
	const navBarContext = useContext(NavbarContext);

	const t = localizationContext.useFormatMessage();
	const getLocalizedValue = useLocalizedValue();

	const specificationsQuery = useQuery(
		['wines-specifications', { currency }],
		() => getAllSpecifications({ IsCache: true, Limit: 999, currency })
	);

	const tagQueryParams = {
		IsShownInFilters: true,
		IsCache: true,
		SortingColumn: VinistoHelperDllEnumsTagSortableColumns.ORDER_IN_FILTER,
		limit: 100,
		currency,
	};
	const { data: tags } = useQuery(['tags', tagQueryParams], () =>
		getAll(tagQueryParams)
	);

	const [query, setQuery] = useURLParams();

	const activeSpecificationFilters = useMemo(
		() =>
			generateRequestFilters(
				specificationsQuery?.data?.specifications ?? [],
				query,
				localizationContext.activeLanguageKey
			),
		[specificationsQuery?.data?.specifications, query, localizationContext]
	);

	const urlParamTags = `${t({ id: 'tags.urlParam' })}`;

	const activeTagFilters = useMemo(() => {
		if (!tags) return [];

		const tagFiltersAsArray: string[] = Array.isArray(query[urlParamTags])
			? query[urlParamTags]
			: [query[urlParamTags]];

		const tagFilters = tagFiltersAsArray
			.map((slug: string) => {
				return tags.find(
					(tag) => removeDiacritics(getLocalizedValue(tag.name ?? [])) === slug
				);
			})
			.filter(
				(tag): tag is Exclude<typeof tag, undefined> => tag !== undefined
			);
		return tagFilters;
	}, [tags, query, urlParamTags, getLocalizedValue]);

	const handleOnRemoveFilter = useCallback(
		(specificationName: string) => () => {
			setQuery({ [specificationName]: undefined });
		},
		[setQuery]
	);

	const sortParamUrlName = `${t({ id: 'category.sorting.urlParam' })}`;
	const sortParam = useMemo(() => {
		const urlParam = query[sortParamUrlName];
		if (urlParam === undefined) {
			return SORTING_DEFAULT;
		}
		return (
			SORTING.filter(
				(sorting) =>
					removeDiacritics(`${t({ id: sorting.title })}`) === urlParam
			)[0] ?? SORTING_DEFAULT
		);
	}, [query, sortParamUrlName, t]);

	const setSortParam = useCallback(
		(sort: BundleSorting) => {
			const sortUrlParam =
				sort === SORTING_DEFAULT
					? undefined
					: removeDiacritics(`${t({ id: sort.title })}`);
			setQuery({ [sortParamUrlName]: sortUrlParam });
		},
		[setQuery, sortParamUrlName, t]
	);

	// Category bundles view mode
	const [view, handleOnViewChange] = useCategoryView();

	const pageParam = useMemo(() => query[URL_PARAM_PAGE] ?? [1], [query]);
	const setPageParam = useCallback(
		(pageArray: [number] | [number, number]) => {
			setQuery({ [URL_PARAM_PAGE]: pageArray });
		},
		[setQuery]
	);

	const page = useMemo(() => getPageFromParam(pageParam), [pageParam]);
	const currentPage = useMemo(() => last(page) as number, [page]);

	const limitParam = useMemo(
		() => query[URL_PARAM_LIMIT] ?? URL_PARAM_LIMIT_DEFAULT_VALUE,
		[query]
	);
	const limit = useMemo(
		() => (!isNumber(limitParam) ? URL_PARAM_LIMIT_DEFAULT_VALUE : limitParam),
		[limitParam]
	);
	const isInStockParam = `${t({ id: 'isInStock.urlParam' })}`;
	const isInStockParamRef = query[isInStockParam];

	const isDiscountedParam = `${t({ id: 'isDiscounted.urlParam' })}`;
	const isDiscountedParamRef = query[isDiscountedParam];

	const bundlePageQueries = useMemo(() => {
		return createBundlePageQueries({
			categoryId: undefined,
			tagId: undefined,
			sortingColumn: sortParam?.sortingColumn || '',
			isSortingDescending: sortParam?.isSortingDescending || false,
			page,
			countryOfSale,
			currency,
			filters: [
				...activeSpecificationFilters.map((filter) => {
					if (
						filter.specificationDefinitionId === PRICE_SPECIFICATION_ID &&
						localizationContext?.activeCurrency.currency !==
							VinistoHelperDllEnumsCurrency.CZK
					) {
						return {
							...filter,
							max: convertEURtoCZK(filter.max),
							min: convertEURtoCZK(filter.min),
						};
					}
					// eslint-disable-next-line @typescript-eslint/no-unused-vars
					const { specificationName, unit, imperialUnit, ...rest } = filter;
					return rest;
				}),
				...(activeTagFilters.length &&
				activeTagFilters.filter((filter) => filter.id !== SALE_TAG_ID).length
					? [
							{
								filterType: FILTER_CODE.TAG,
								tags: activeTagFilters
									.map((filter) => filter.id)
									.filter((filter) => filter !== SALE_TAG_ID),
							},
					  ]
					: []),
				...(isInStockParamRef
					? [
							{
								filterType: FILTER_CODE.STOCK,
								isInStock: true,
							},
					  ]
					: []),
				...(isDiscountedParamRef
					? [
							{
								filterType: FILTER_CODE.DISCOUNT,
								isDiscounted: true,
							},
					  ]
					: []),
			],
			limit,
			enabled: specificationsQuery?.isFetched,
			onSuccess: (data) => {
				if (data?.bundles) {
					const allIds = data.bundles.map((bundle) => bundle.id);
					warehouseContext.fetchQuantity(allIds);
				}
			},
			onError: () => {
				setPageParam([1]);
			},
		});
	}, [
		sortParam?.sortingColumn,
		sortParam?.isSortingDescending,
		page,
		localizationContext?.countryOfSale,
		localizationContext?.activeCurrency?.currency,
		activeSpecificationFilters,
		activeTagFilters,
		isInStockParamRef,
		isDiscountedParamRef,
		limit,
		specificationsQuery?.isFetched,
		warehouseContext,
		setPageParam,
	]);

	const { filtersQuery } = useBundleQueries({
		categoryId: null,
		tagId: null,
		sortParam,
		page,
		limit,
		activeSpecificationFilters,
		activeTagFilters,
		isInStockParamRef,
		isDiscountedParamRef,
	});

	type BundlesBatchedByPages =
		UseQueryOptions<VinistoProductDllModelsApiBundleBundlesReturn>[];

	const paginatedBundlesData = useQueries<BundlesBatchedByPages>({
		queries: bundlePageQueries,
	});

	const bundlesCount = useMemo(
		() => getBundlesCount(paginatedBundlesData),
		[paginatedBundlesData]
	);
	const bundlesToLoadMore = useMemo(
		() => calculateBundlesToLoadMore(bundlesCount, currentPage, limit),
		[bundlesCount, currentPage, limit]
	);
	const isBundlesLoading = useMemo(
		() =>
			specificationsQuery?.isLoading ||
			paginatedBundlesData.some((item) => item.isLoading),
		[paginatedBundlesData, specificationsQuery]
	);
	const bundles = useMemo(
		() =>
			// TODO Refactor, this has to be a shareable function with object parameter
			generateBundlesToShow(
				paginatedBundlesData,
				bundlesCount,
				currentPage,
				limit,
				isBundlesLoading
			),
		[bundlesCount, paginatedBundlesData, currentPage, isBundlesLoading, limit]
	);

	const isInStockActive = Boolean(isInStockParamRef);
	const isDiscountedActive = Boolean(isDiscountedParamRef);

	const totalActiveFiltersCount =
		activeTagFilters.length +
		activeSpecificationFilters.length +
		(isInStockActive ? 1 : 0) +
		(isDiscountedActive ? 1 : 0);

	// This is zipping specifications data (get-category-specification, get-tag-specification) with get-avaliable-filters data
	// The resultuing object is used to render the filters. Its shape is somewhat weird and should be probably refactored
	const specificationsWithBundleFilters = useMemo(() => {
		return mergeSpecificationsWithBundleFilters(
			specificationsQuery.data?.specifications ?? [],
			filtersQuery.data?.specificationFilters ?? []
		);
	}, [
		filtersQuery.data?.specificationFilters,
		specificationsQuery.data?.specifications,
	]);

	return (
		<BundlesWithFiltersContext.Provider
			value={{
				activeSpecificationFilters,
				activeTagFilters,
				bundlesData: paginatedBundlesData,
				bundles,
				bundlesCount,
				bundlesToLoadMore,
				currentPage,
				isBundlesLoading,
				isDataLoading: false,
				handleOnRemoveFilter,
				handleOnViewChange,
				limit,
				page,
				query,
				setPageParam,
				isInStockParam,
				isInStockParamRef,
				isDiscountedParam,
				isInStockActive,
				isDiscountedActive,
				totalActiveFiltersCount,
				setQuery,
				specificationsQuery,
				specificationsWithBundleFilters,
				view,
				sorting: sortParam,
				setSorting: setSortParam,
				isVisible: navBarContext.isFiltersVisible,
				setIsVisible: navBarContext.setIsFiltersVisible,
			}}
		>
			{props.children}
		</BundlesWithFiltersContext.Provider>
	);
};

export default BundlesWithFiltersProvider;
