import { useContext } from 'react';
import getBundleImage, {
	IMAGE_SIZE_THUMB_368x490,
} from 'Helpers/getBundleImage';
import getFlagSpecification from 'Helpers/getFlagSpecification';
import useIdenticalBundles from 'Hooks/Queries/useIdenticalBundles';
import useLocalizedValue from 'Hooks/useLocalizedValue';
import { BundleDetailProps } from 'Pages/Bundle/interfaces';
import { LocalizationContext } from 'Services/LocalizationService';
import { WarehouseContext } from 'Services/WarehouseService';

import {
	VinistoHelperDllEnumsCountryCode,
	VinistoImageDllModelsApiImageImage,
	VinistoProductDllModelsApiBundleBundle,
} from '@/api-types/product-api';
import { bundleAdapter } from '@/index';

export const useBundleDetail = (
	bundle: VinistoProductDllModelsApiBundleBundle
): BundleDetailProps => {
	const localizationContext = useContext(LocalizationContext);
	const { activeCurrency } = localizationContext;
	const warehouseContext = useContext(WarehouseContext);
	const getLocalizedValue = useLocalizedValue();

	const id = bundle?.id ?? '';
	const bundleCateogryId = bundle?.categories?.[0] ?? '';
	const bundleCateogryIds = bundle?.categories ?? [];
	const bundleName = getLocalizedValue(bundle?.name ?? []);
	const bundleDescription = getLocalizedValue(bundle?.description ?? []);
	const bundleShortDescription = getLocalizedValue(
		bundle?.shortDescription ?? []
	);
	const bundlePriceCurrency = localizationContext.activeCurrency.currency ?? '';
	const bundleEvaluation = bundle?.bundleEvaluation ?? {};
	const bundleAverageRating = (bundleEvaluation.averageStars ?? 0) / 2;
	const reviewCount = bundleEvaluation.totalEvaluationCount ?? 0;
	const { data: identicalBundlesData } = useIdenticalBundles(id, {
		currency: activeCurrency.currency,
		countryOfSale:
			localizationContext.countryOfSale as VinistoHelperDllEnumsCountryCode,
	});
	const suppliers = [bundle, identicalBundlesData];
	const isOnlySupplier = suppliers.length === 1;
	const totalEvaluationCount = bundleEvaluation.totalEvaluationCount ?? 0;
	const categoryId = (bundle?.categories ?? [])?.[0];
	const temporaryUnavailable = bundle?.temporaryUnavailable ?? false;
	const isSaleOver = bundle?.isSaleOver ?? false;
	const isGift = bundle?.isGift ?? false;
	const specificationDetails = bundle?.specificationDetails ?? [];
	const {
		shortVariety: bundleProducerName,
		varietyUrl: bundleProducerUrl,
		component: bundleFlag,
	} = getFlagSpecification(specificationDetails ?? []);
	const bundleImageSmall = getBundleImage(
		bundle?.images ?? [],
		IMAGE_SIZE_THUMB_368x490
	);
	const bundleImages =
		bundle?.images
			?.sort((a) => (a.isMain ? -1 : 1))
			.map((image: VinistoImageDllModelsApiImageImage) => {
				return {
					src: image?.domainUrls?.original_png ?? '',
				};
			}) ?? [];
	const availableQuantity = [
		warehouseContext.getQuantity(bundle?.id ?? ''),
		...(isOnlySupplier
			? []
			: [
					...(identicalBundlesData?.map((bundle) =>
						warehouseContext.getQuantity(`${bundle?.id}`)
					) || []),
			  ]),
	].filter((x): x is number => x !== undefined);
	const supplierName = bundle?.supplier?.nameWeb ?? '';
	const originalBundleObject = bundle as VinistoProductDllModelsApiBundleBundle;
	const domainBundle = bundleAdapter.fromApi(bundle, {
		currency: activeCurrency.currency,
	});
	const bundleUrl = getLocalizedValue(bundle?.url ?? []);
	const tagsDetails = bundle?.tagsDetail ?? [];
	const setBundles =
		bundle?.setBundles?.map((setBundle) => {
			const items = bundle.items.filter((i) => i.itemId === setBundle.id);
			return {
				...setBundle,
				setItems: items.map((item) => ({
					...item,
					...('id' in item && item.id
						? {
								originalPrice:
									domainBundle.bundlePrices.setItemsPrices?.[item.id]
										.originalPrice,
								setPrice:
									domainBundle.bundlePrices.setItemsPrices?.[item.id].setPrice,
						  }
						: {}),
				})),
			};
		}) ?? [];
	const items = bundle?.items ?? [];

	return {
		id,
		bundleCateogryId,
		bundleCateogryIds,
		bundleName,
		bundleDescription,
		bundleShortDescription,
		bundlePriceCurrency,
		bundleEvaluation,
		bundleAverageRating,
		reviewCount,
		isOnlySupplier,
		totalEvaluationCount,
		categoryId,
		temporaryUnavailable,
		isGift,
		isSaleOver,
		specificationDetails,
		bundleProducerName,
		bundleProducerUrl,
		bundleFlag,
		bundleImageSmall,
		bundleImages,
		availableQuantity,
		supplierName,
		originalBundleObject,
		domainBundle,
		bundleUrl,
		tagsDetail: tagsDetails,
		items,
		prices: bundle?.prices,
		productsDetail: bundle?.productsDetail,
		isSet: bundle?.isSet ?? false,
		setBundles,
	};
};
