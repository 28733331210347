import { FC, useContext } from 'react';
import Config from 'Config';
import { IPaymentInfoProps } from 'Pages/CartConfirmation/Components/PaymentInfo/interfaces';
import { LocalizationContext } from 'Services/LocalizationService';
import { BasketContext } from 'Services/BasketService';
import { getLocalizedPrice } from 'vinisto_shared/src/price/get-localized-price';

import styles from './styles.module.css';

import { VinistoHelperDllEnumsCurrency } from '@/api-types/product-api';

const PaymentInfo: FC<IPaymentInfoProps> = ({
	orderId,
	orderNumber,
	orderPrice,
}) => {
	const localizationContext = useContext(LocalizationContext);
	const basketContext = useContext(BasketContext);
	const t = localizationContext.useFormatMessage();
	const currency = basketContext?.basketState?.currency;

	return (
		<>
			<div className="container">
				<div className="row">
					<div className="col-12">
						<h2 className="vinisto-heading underline">
							{t({
								id: 'cartShippingPayment.paymentInfo',
							})}
						</h2>
						<div className="d-flex flex-row mt-1 mb-2 gap-4">
							<img
								src={`${Config.baseUrl}order-api/orders/${orderId}/GetQrCode/img.png`}
								alt="qr"
								className="vinisto-summary-page-qrcode mt-1"
							/>
							<div className={styles.wrap}>
								<div>
									{`${t({
										id: 'cartShippingPayment.accountNumber',
									})}/${t({
										id: 'cartShippingPayment.bankAccount.code',
									})}: `}
									<strong>{`${
										currency === VinistoHelperDllEnumsCurrency.EUR
											? Config.market.bankAccountEUR
											: Config.market.bankAccount
									}`}</strong>
								</div>
								<div>
									{`${t({
										id: 'cartShippingPayment.varSymbol',
									})}: `}
									<strong>{orderNumber}</strong>
								</div>
								<div>
									{`${t({
										id: 'cartShippingPayment.payment',
									})}: `}
									<strong>
										{getLocalizedPrice({
											price: orderPrice,
											// @ts-expect-error possible "undefined" issue
											currency,
										})}
									</strong>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="container">
				<div className="row">
					<div className="col-12">
						<h2 className="vinisto-heading underline mt-2 mt-xxl-4">
							{t({
								id: 'cartShippingPayment.info',
							})}
						</h2>
						<div className={styles.info}>
							<p className={styles.infoText}>
								{t(
									{ id: 'cartShippingPayment.bankAccount.summary' },
									{
										orderNumber: (
											<span className="fw-bolder">
												{t(
													{
														id: 'cartShippingPayment.bankAccount.summary.orderNumber',
													},
													{ orderNumber }
												)}
											</span>
										),
										paymentType: (
											<span className="fw-bolder">
												{t({
													id: 'cartShippingPayment.bankAccount.summary.bankAccount',
												})}
											</span>
										),
									}
								)}
							</p>
							<p className={styles.infoText}>
								{t(
									{ id: 'cartShippingPayment.bankAccount.summary.disclaimer' },
									{
										highlighted: (
											<span className="fw-bolder vinisto-color-error">
												{t({
													id: 'cartShippingPayment.bankAccount.summary.disclaimer.highlighted',
												})}
											</span>
										),
									}
								)}
							</p>
							<p className={styles.infoText}>
								{t(
									{ id: 'cartShippingPayment.bankAccount.summary.follow' },
									{
										highlighted: (
											<span className="fw-bolder">
												{t({
													id: 'cartShippingPayment.bankAccount.summary.follow.highlighted',
												})}
											</span>
										),
									}
								)}
							</p>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default PaymentInfo;
