import { useContext } from 'react';
import cx from 'classnames';
import useLocalizedValue from 'Hooks/useLocalizedValue';
import getBundleImage, { IMAGE_SIZE_THUMB_64x80 } from 'Helpers/getBundleImage';
import { LocalizationContext } from 'Services/LocalizationService';
import BundleProducer from 'Components/ProductBox/Components/BundleProducer';
import getFlagSpecification from 'Helpers/getFlagSpecification';
import StockAvailability from 'Components/StockAvailability';
import { WarehouseContext } from 'Services/WarehouseService';
import { DirectQuantityBox } from 'Components/QuantityBox';
import { DeviceServiceContext } from 'Services/DeviceService';
import ProductBox from 'Components/ProductBox';
import Skeleton from 'react-loading-skeleton';

import styles from './styles.module.css';

import { VinistoProductDllModelsApiBundleBundle } from '@/api-types/product-api';
import { bundleAdapter } from '@/index';
import { BundlePrice } from '@/domain/price';

type Props = {
	bundle: VinistoProductDllModelsApiBundleBundle;
	displayCta?: boolean;
};

const SearchResultBundleItem = ({ bundle, displayCta = false }: Props) => {
	const { isMobile } = useContext(DeviceServiceContext);
	const warehouseContext = useContext(WarehouseContext);
	const localizationContext = useContext(LocalizationContext);

	const getLocalizedValue = useLocalizedValue();
	const t = localizationContext.useFormatMessage();
	const { activeCurrency } = localizationContext;

	const name = getLocalizedValue(bundle.name);

	const availableQuantity = [warehouseContext.getQuantity(bundle.id)].filter(
		(x): x is number => x !== undefined
	);

	const { shortVariety: producerName, component: flag } = getFlagSpecification(
		bundle.specificationDetails
	);

	const { bundlePrices } = bundleAdapter.fromApi(bundle, {
		currency: activeCurrency.currency,
	});

	if (isMobile) {
		return (
			<div className="vinisto-search-productbox">
				<ProductBox
					bundleData={bundle}
					showAddToBasketBtn={displayCta}
					showSpecifications={false}
					showProducer={true}
					showAddToFavoritesBtn={false}
				/>
			</div>
		);
	}

	return (
		<div className={styles.wrapper}>
			<div className={styles.image}>
				<img
					src={getBundleImage(bundle.images ?? [], IMAGE_SIZE_THUMB_64x80)}
					alt={`${t({ id: 'alt.bundleImage' })}`}
				/>
			</div>
			<div className={styles.info}>
				<span className={styles.name}>{name}</span>
				{producerName && (
					<BundleProducer
						flag={flag}
						name={producerName}
						className={styles.producer}
					/>
				)}
			</div>
			<div className={styles.metadata}>
				<div className={styles.stock}>
					<StockAvailability
						availableQuantity={availableQuantity}
						deliveryDate={warehouseContext.deliveryDate}
						fallback={<Skeleton width="130px" />}
					/>
				</div>
				<Prices
					bundlePrices={bundlePrices}
					isSet={bundle.isSet ?? false}
				/>
			</div>
			{displayCta && (
				<div
					onClick={(e) => e.stopPropagation()}
					className={styles.cta}
					role="button"
					tabIndex={0}
				>
					<DirectQuantityBox bundle={bundle} />
				</div>
			)}
		</div>
	);
};

export default SearchResultBundleItem;

export const Prices = ({
	bundlePrices,
	isSet,
}: {
	bundlePrices: BundlePrice;
	isSet: boolean;
}) => {
	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();

	const {
		isDiscounted,
		basePrice,
		discountedPrice,
		discountDifferenceAsPercentage,
	} = bundlePrices;

	const percentageDiscount = Number(discountDifferenceAsPercentage).toFixed(0);

	if (isSet && !isDiscounted) {
		return (
			<div className={styles.prices}>
				<div className={styles.pricesWithVAT}>
					<span className={styles.basePrice}>
						{discountedPrice?.getFormatedValueWithVat()}
					</span>
				</div>
				<span className={styles.priceWithoutVAT}>
					{`${t({ id: 'basket.priceWithoutVAT' })} `}
					<span className={styles.value}>
						{discountedPrice?.getFormatedValue()}
					</span>
				</span>
			</div>
		);
	}

	if (!isDiscounted) {
		return (
			<div className={styles.prices}>
				<div className={styles.pricesWithVAT}>
					<span className={styles.basePrice}>
						{basePrice?.getFormatedValueWithVat()}
					</span>
				</div>
				<span className={styles.priceWithoutVAT}>
					{`${t({ id: 'basket.priceWithoutVAT' })} `}
					<span className={styles.value}>{basePrice?.getFormatedValue()}</span>
				</span>
			</div>
		);
	}

	return (
		<div className={styles.prices}>
			<div className={styles.pricesWithVAT}>
				<span className={cx(styles.basePrice, styles.hasDiscount)}>
					{basePrice.getFormatedValueWithVat()}
				</span>
				<span className={styles.discountedPrice}>
					{discountedPrice?.getFormatedValueWithVat()}
				</span>
				<span className={styles.percentageDiscount}>{percentageDiscount}%</span>
			</div>
			<span className={styles.priceWithoutVAT}>
				{`${t({ id: 'basket.priceWithoutVAT' })} `}
				<span className={styles.value}>
					{discountedPrice?.getFormatedValue()}
				</span>
			</span>
		</div>
	);
};
