import { VinistoProductDllModelsApiBundleBundle } from 'vinisto_api_client/src/api-types/product-api';
import { ARTICLE_PRODUCT_TYPE } from 'Services/ApiService/Cms/Blog/constants';
import CarouselSection from 'Components/CarouselSection';
import ProductBox from 'Components/ProductBox';

import styles from './styles.module.css';

const PostLinkedBundles = ({
	bundles,
	listingType,
	bundlesTitle,
}: {
	bundles: VinistoProductDllModelsApiBundleBundle[];
	listingType?: ARTICLE_PRODUCT_TYPE;
	bundlesTitle: string;
}) => {
	if (!listingType || listingType == ARTICLE_PRODUCT_TYPE.NONE) return <></>;

	if (
		listingType == ARTICLE_PRODUCT_TYPE.CAROUSEL_SPECIFICATION ||
		listingType == ARTICLE_PRODUCT_TYPE.CAROUSEL_MANUAL
	) {
		return (
			<div className={styles.linkedBundlesCarousel}>
				<CarouselSection
					title={bundlesTitle}
					data={bundles}
				/>
			</div>
		);
	}
	return (
		<>
			{bundles?.length > 0 && (
				<div className={styles.linkedBundlesWrapper}>
					<h2 className={styles.linkedBundlesTitle}>{bundlesTitle}</h2>
					<div className={styles.linkedBundles}>
						{bundles.map((bundle) => (
							<ProductBox
								bundleData={bundle}
								key={bundle.id}
								showAddToBasketBtn={true}
								showPercentageDiscount={true}
							/>
						))}
					</div>
				</div>
			)}
		</>
	);
};

export default PostLinkedBundles;
