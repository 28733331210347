import { MouseEvent, useContext, useRef } from 'react';
import cx from 'classnames';
import { get } from 'lodash-es';
import { Link } from 'react-router-dom';
import Config from 'Config';
import getBundleImage, { IMAGE_SIZE_THUMB_64x80 } from 'Helpers/getBundleImage';
import useLocalizedDateTime from 'Hooks/useLocalizedDateTime';
import useLocalizedValue from 'Hooks/useLocalizedValue';
import useLocalizedStreetAndNumber from 'Hooks/useLocalizedStreetAndNumber';
import { LocalizationContext } from 'Services/LocalizationService';
import { GoPayServiceContext } from 'Services/GoPayService';
import {
	VinistoHelperDllEnumsCurrency,
	VinistoHelperDllEnumsOrderOrderState,
	VinistoHelperDllEnumsOrderPaymentType,
} from 'vinisto_api_client/src/api-types/order-api';
import BundleProducer from 'Components/ProductBox/Components/BundleProducer';
import DiscountCoupon from 'Components/DiscountCoupon/index';
import { DiscountCouponPageType } from 'Components/DiscountCoupon/interfaces';
import useBundleData from 'Pages/UserSection/Orders/Hooks/useBundleData';
import useAddItemsToBasket, {
	showBuyAgainButton,
} from 'Hooks/use-add-items-to-basket';
import userSectionStyles from 'Pages/UserSection/styles.module.css';
import { CREATED } from 'Pages/UserSection/Orders/constants';
import useOrders from 'Pages/UserSection/Orders/Hooks/useOrders';
import { DeviceServiceContext } from 'Services/DeviceService';
import { OrderItem } from 'vinisto_ui';
import Skeleton from 'react-loading-skeleton';
import { IBundleChange } from 'Services/BasketService/interfaces';
import { getDiscountPriceValues } from 'vinisto_shared/src/price/get-discount-prices';
import { getLocalizedPrice } from 'vinisto_shared/src/price/get-localized-price';

import { OrderProps } from '../interfaces';

import { getFilenameFromPath } from './helpers';
import styles from './styles.module.css';
import ProductMobile from './ProductMobile';
import ProductDesktop from './ProductDesktop';
import GiftDesktop from './GiftDesktop';

const Order = ({
	isLoading = false,
	order,
	handleOnClickInvoice,
}: OrderProps) => {
	const goPayContext = useContext(GoPayServiceContext);
	const t = useContext(LocalizationContext).useFormatMessage();
	const { isMobile, isTablet, isDesktop } = useContext(DeviceServiceContext);

	const getLocalizedDate = useLocalizedDateTime();
	const getLocalizedValue = useLocalizedValue();
	const getStreetAndNumber = useLocalizedStreetAndNumber();
	const getBundleData = useBundleData();
	const paymentInfoRef = useRef<HTMLDivElement>(null);
	const { addItemsToBasket } = useAddItemsToBasket();

	if (!order || !order.orderCurrency) return null;

	const orderId = order.id;
	const orderDate = order.date;
	const orderCurrency = order.orderCurrency;
	const orderState = order.localizedState;
	const orderNumber = order.orderNumber || orderId;
	const orderTotalPriceWithVAT = order.orderPriceWithVat ?? 0;
	const discountCoupons = order.discountCoupons;
	const pdfProformaUrl = order?.invoice?.pdfProformaUrl ?? '';
	const pdfVatInvoiceUrl = order?.invoice?.pdfVatInvoiceUrl ?? '';
	const pdfUrl = order?.invoice?.pdfUrl ?? '';
	const hasDocumentsAttached = pdfProformaUrl || pdfVatInvoiceUrl || pdfUrl;

	const states = order?.state
		?.split(',')
		?.map((state: string) => state?.trim());
	const lastState = states?.[states?.length - 1];

	const showPayOnlineBtn =
		lastState === CREATED && order?.payment?.goPayId && !isLoading;

	const showPaymentInfo =
		lastState === CREATED &&
		order?.payment?.paymentType ===
			VinistoHelperDllEnumsOrderPaymentType.BANK_TRANSFER &&
		!isLoading;

	const { showCancelOrderBtn, handleOnClickCancelOrder } = useOrders(
		lastState,
		isLoading,
		order
	);

	const trackingLink = order.delivery?.trackingUrl ?? '';

	const isOrderCancelled = states?.includes(
		VinistoHelperDllEnumsOrderOrderState.CANCELLED
	);

	const handleOnClickPayOnline = (e: MouseEvent) => {
		e.stopPropagation();
		goPayContext.handleOnPayOnline(order);
	};

	const handleOnClickScrollToPaymentInfo = (e: MouseEvent) => {
		e.stopPropagation();
		setTimeout(() => paymentInfoRef?.current?.scrollIntoView(), 1);
	};

	const itemsToAdd: IBundleChange[] = order?.orderItems
		?.filter((orderItem: any) => !orderItem.bundle.isGift)
		.map((orderItem: any) => {
			const id = orderItem?.bundle?.id ?? '';

			return {
				bundleId: id,
				quantity: orderItem.quantity,
			};
		});

	return (
		<div className={styles.order}>
			{isMobile || isTablet ? (
				<>
					<div className={styles.spaceBetween}>
						<div className={styles.orderNumber}>
							{isLoading ? (
								<Skeleton width="180px" />
							) : (
								t({ id: 'userSection.order.title' }, { value: orderNumber })
							)}
						</div>
						<div
							className={styles.orderStatus}
							style={{
								color: orderState?.color ?? '',
							}}
						>
							{isLoading ? (
								<Skeleton width="120px" />
							) : (
								t({ id: orderState.text })
							)}
						</div>
					</div>
					<div className={cx(styles.spaceBetween, styles.delimiter)}>
						<div>
							<span className={styles.priceInfo}>
								{`${t({
									id: 'userSection.order.price.total.mobile',
								})} `}
							</span>
							<span className={styles.totalPrice}>
								{isLoading ? (
									<Skeleton width="160px" />
								) : (
									getLocalizedPrice({
										price: orderTotalPriceWithVAT,
										currency: orderCurrency,
									})
								)}
							</span>
						</div>
						<div className={styles.date}>
							{isLoading ? (
								<Skeleton width="85px" />
							) : (
								orderDate && getLocalizedDate(orderDate)
							)}
						</div>
					</div>
				</>
			) : (
				<OrderItem
					id={orderId}
					isLoading={isLoading}
					internalId={orderNumber}
					totalPrice={order.orderPriceWithVat ?? 0}
					currency={orderCurrency}
					state={orderState.text && (t({ id: orderState.text }) as string)}
					color={orderState.color ?? ''}
					createdAtDate={getLocalizedDate(orderDate)}
					isDesktop={isDesktop}
					trackingLink={order.delivery?.trackingUrl ?? ''}
					showBuyAgainButton={showBuyAgainButton(order.state)}
					addItemsToBasket={addItemsToBasket}
					itemsToAdd={itemsToAdd}
					showDetailButton={false}
					orderNumberAsLink={false}
					className={styles.orderDesktop}
				/>
			)}

			{(isMobile || isTablet) && (
				<div className={styles.buttons}>
					{showPayOnlineBtn && (
						<button
							className={styles.primaryButton}
							onClick={handleOnClickPayOnline}
						>
							{t({ id: 'userSection.order.btn.payOnline' })}
						</button>
					)}
					{showPaymentInfo && (
						<button
							className={styles.primaryButton}
							onClick={handleOnClickScrollToPaymentInfo}
						>
							{t({ id: 'userSection.order.btn.paymentInfo' })}
						</button>
					)}
					{!showBuyAgainButton(order.state) && trackingLink && (
						<a
							href={trackingLink}
							className={styles.linkButton}
						>
							Sledovat zásilku
						</a>
					)}
					{showBuyAgainButton(order.state) && (
						<button
							className={styles.primaryButton}
							disabled={isLoading}
							onClick={async () => await addItemsToBasket(itemsToAdd)}
						>
							{t({ id: 'userSection.order.btn.orderAgain' })}
						</button>
					)}
				</div>
			)}

			{hasDocumentsAttached && !isOrderCancelled && (
				<>
					<h2 className={userSectionStyles.userSectionHeader}>
						{t({ id: 'userSection.documents' })}
					</h2>
					<div className={styles.documents}>
						{pdfProformaUrl && (
							<button
								onClick={
									handleOnClickInvoice &&
									handleOnClickInvoice(orderId, pdfProformaUrl as string)
								}
								className={styles.documentLink}
							>
								{getFilenameFromPath(pdfProformaUrl)}
							</button>
						)}
						{pdfVatInvoiceUrl && (
							<button
								onClick={
									handleOnClickInvoice &&
									handleOnClickInvoice(orderId, pdfVatInvoiceUrl as string)
								}
								className={styles.documentLink}
							>
								{getFilenameFromPath(pdfVatInvoiceUrl)}
							</button>
						)}
						{pdfUrl && (
							<button
								onClick={
									handleOnClickInvoice &&
									handleOnClickInvoice(orderId, pdfUrl as string)
								}
								className={styles.documentLink}
							>
								{getFilenameFromPath(pdfUrl)}
							</button>
						)}
					</div>
				</>
			)}

			<h2 className={userSectionStyles.userSectionHeader}>
				{t({ id: 'orderConfirmation.orderSummary.title' })}
			</h2>
			<div>
				{order?.orderItems?.map((orderItem, index: number) => {
					const { flagComponent, url, quantity, producerSpecification } =
						getBundleData(orderItem);

					const basePrice = orderItem.bundle?.price;
					const discountedPrice = orderItem.bundle?.discountPrice;

					const bundleQuantity = orderItem.quantity ?? 1;

					const {
						discountedPriceWithoutVat,
						discountedPriceWithVat,
						isDiscounted,
					} = getDiscountPriceValues({
						quantityInBasket: bundleQuantity,
						basePrice,
						discountedPrice,
					});

					const priceWithVat = isDiscounted
						? discountedPriceWithVat
						: basePrice?.valueWithVat;

					const priceWithoutVat = isDiscounted
						? discountedPriceWithoutVat
						: basePrice?.value;

					const totalPriceWithVAT = (priceWithVat ?? 0) * quantity;

					const totalPriceWithoutVAT = (priceWithoutVat ?? 0) * quantity;

					return isMobile || isTablet ? (
						<ProductMobile
							key={`order-item-mobile-bundle-${orderItem.bundle.id}-${index}`}
							isLoading={isLoading}
							orderItem={orderItem}
							index={index}
							url={url}
							flagComponent={flagComponent}
							producerSpecification={producerSpecification}
							quantity={quantity}
							totalPriceWithVAT={totalPriceWithVAT}
							orderCurrency={orderCurrency}
							totalPriceWithoutVAT={totalPriceWithoutVAT}
						/>
					) : (
						<ProductDesktop
							key={`order-item-mobile-bundle-${orderItem.bundle.id}-${index}`}
							isLoading={isLoading}
							orderItem={orderItem}
							index={index}
							url={url}
							flagComponent={flagComponent}
							producerSpecification={producerSpecification}
							quantity={quantity}
							totalPriceWithVAT={totalPriceWithVAT}
							totalPriceWithoutVAT={totalPriceWithoutVAT}
							orderCurrency={orderCurrency}
							itemPriceWithVAT={priceWithVat ?? 0}
							itemPriceWithoutVAT={priceWithoutVat ?? 0}
						/>
					);
				})}
			</div>
			<div>
				{order?.giftItems?.map((giftItem, index: number) => {
					const { flagComponent, url, quantity, producerSpecification } =
						getBundleData(giftItem);

					return isMobile || isTablet ? (
						<div
							className={styles.product}
							key={`order-item-mobile-bundle-${giftItem?.bundle?.id}-${index}`}
						>
							{!isLoading && (
								<Link
									className={styles.overlay}
									to={`/${t({
										id: 'routes.product.route',
									})}/${url}`}
								/>
							)}
							<div className={styles.productImg}>
								<img
									src={getBundleImage(
										[giftItem.bundle?.mainImage ?? {}],
										IMAGE_SIZE_THUMB_64x80
									)}
									alt={`${t({
										id: 'alt.bundleImage',
									})}`}
								/>
							</div>
							<div className={styles.productName}>
								{giftItem.bundle?.name ?? ''}
							</div>
							<div className={styles.productProducer}>
								<BundleProducer
									flag={flagComponent}
									name={producerSpecification}
								/>
							</div>
							<div className={styles.productCount}>
								{t(
									{ id: 'order.pcs' },
									{
										count: quantity,
									}
								)}
							</div>
							<div className={styles.productPrice}>
								{t({ id: 'giftInfo.price.freeSmall' })}
							</div>
						</div>
					) : (
						<GiftDesktop
							key={`gift-item-mobile-bundle-${giftItem?.bundle?.id}-${index}`}
							isLoading={isLoading}
							orderItem={giftItem}
							index={index}
							url={url}
							quantity={quantity}
						/>
					);
				})}
			</div>

			{discountCoupons &&
				discountCoupons?.map((discountCoupon, index: number) => {
					return (
						<DiscountCoupon
							key={index}
							discountCoupon={discountCoupon}
							discountCouponPage={DiscountCouponPageType.USER_SECTION}
							className={styles.discountCoupon}
							orderCurrency={orderCurrency}
						/>
					);
				})}
			<div className={styles.shipping}>
				<span>
					{t(
						{ id: 'userSection.order.delivery.title' },
						{
							value: getLocalizedValue(order?.delivery?.name ?? []),
						}
					)}
				</span>
				<span>
					{get(order, 'delivery.price.value') === 0
						? t({ id: 'basket.price.free' })
						: getLocalizedPrice({
								price: get(order, 'delivery.price.valueWithVat') ?? 0,
								currency: orderCurrency,
						  })}
				</span>
			</div>
			<div className={styles.payment}>
				<span>
					{t(
						{ id: 'userSection.order.payment.title' },
						{
							value: getLocalizedValue(get(order, 'payment.name', [])),
						}
					)}
				</span>
				<span>
					{get(order, 'payment.price.value') === 0
						? t({ id: 'basket.price.free' })
						: getLocalizedPrice({
								price: get(order, 'payment.price.valueWithVat') ?? 0,
								currency: orderCurrency,
						  })}
				</span>
			</div>

			{showPaymentInfo && (
				<div className="vinisto-user-orders__orders__order-body__item flex-column">
					<div
						className="vinisto-user-orders__orders__order-body__item-payment-info"
						ref={paymentInfoRef}
					>
						<div className="vinisto-user-orders__orders__order-body__item__info d-flex flex-column align-items-start">
							<div className="mb-2">
								<strong>
									{t({
										id: 'cartShippingPayment.paymentInfo',
									})}
								</strong>
							</div>
							<dl className={styles.paymentDetails}>
								<dt>
									{t({
										id: 'cartShippingPayment.accountNumber',
									})}
									/
									{t({
										id: 'cartShippingPayment.bankAccount.code',
									})}
								</dt>
								<dd>
									{orderCurrency === VinistoHelperDllEnumsCurrency.EUR
										? Config.market.bankAccountEUR
										: Config.market.bankAccount}
								</dd>
								<dt>
									{t({
										id: 'cartShippingPayment.varSymbol',
									})}
								</dt>
								<dd>
									<strong>{orderNumber}</strong>
								</dd>
								<dt>
									{t({
										id: 'cartShippingPayment.payment',
									})}
								</dt>
								<dd>
									<strong>
										{getLocalizedPrice({
											price: orderTotalPriceWithVAT,
											currency: orderCurrency,
										})}
									</strong>
								</dd>
							</dl>
						</div>

						<div className="vinisto-user-orders__orders__order-body__item__prices">
							<div className="vinisto-user-orders__orders__order-body__item__prices__shipping">
								<img
									src={`${Config.baseUrl}order-api/orders/${orderId}/GetQrCode/img.png`}
									alt="qr"
									className="vinisto-user-orders__orders__order-body__item__qrcode"
								/>
							</div>
						</div>

						<div className="vinisto-user-orders__orders__order__spacer"></div>
					</div>
				</div>
			)}

			<h2 className={userSectionStyles.userSectionHeader}>
				{t({
					id: 'orderConfirmation.orderSummary.address.deliveryShipping.title',
				})}
			</h2>

			<div>
				{order?.delivery?.pickupPoint && (
					<div className={styles.address}>
						<div className={styles.addressTitle}>
							{t({
								id: 'userSection.order.shippingDetails.title',
							})}
						</div>
						<div>{order?.delivery?.pickupPoint?.addressee}</div>
						<div>{order?.delivery?.pickupPoint?.email}</div>
						<div>{order?.delivery?.pickupPoint?.phone}</div>
						<div>{getStreetAndNumber(order.delivery.pickupPoint ?? {})}</div>
						{/* @ts-expect-error  city does not exist on the interface */}
						<div>{order?.delivery?.pickupPoint?.city}</div>
						{/* @ts-expect-error  zip does not exist on the interface */}
						<div>{order?.delivery?.pickupPoint?.zip}</div>
						<div>{order?.delivery?.pickupPoint?.code}</div>
					</div>
				)}
				{order?.delivery?.deliveryAddress && (
					<div className={styles.address}>
						<div className={styles.addressTitle}>
							{t({
								id: 'userSection.order.shippingDetails.title',
							})}
						</div>
						<div>
							{`${order?.delivery?.deliveryAddress?.name} ${order?.delivery?.deliveryAddress?.surname}`}
						</div>
						<div>{order?.delivery?.deliveryAddress?.email}</div>
						<div>{order?.delivery?.deliveryAddress?.phone}</div>
						<div>
							{getStreetAndNumber(order.delivery.deliveryAddress ?? {})}
						</div>
						<div>{order?.delivery?.deliveryAddress?.city}</div>
						<div>{order?.delivery?.deliveryAddress?.zip}</div>
						<div>{order?.delivery?.deliveryAddress?.countryCode}</div>
					</div>
				)}

				<div className={styles.address}>
					<div className={styles.addressTitle}>
						{t({
							id: 'userSection.order.billingInfo.title',
						})}
					</div>
					<div>
						{`${order?.billingAddress?.name} ${order?.billingAddress?.surname}`}
					</div>
					<div>{order?.billingAddress?.email}</div>
					<div>{order?.billingAddress?.phone}</div>
					<div>{getStreetAndNumber(order.billingAddress ?? {})}</div>
					<div>{order?.billingAddress?.city}</div>
					<div>{order?.billingAddress?.zip}</div>
					<div>{order?.billingAddress?.countryCode}</div>
				</div>
			</div>

			{showCancelOrderBtn && (
				<div>
					<button
						className={cx(
							'underline-effect underline-effect--vinisto fw-bolder',
							styles.cancelOrderBtn
						)}
						onClick={handleOnClickCancelOrder}
					>
						<span className="underline-item">
							{t({ id: 'userSection.order.btn.cancel' })}
						</span>
					</button>
				</div>
			)}
		</div>
	);
};

export default Order;
