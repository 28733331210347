import { useQuery } from '@tanstack/react-query';

import BundleService from '@/product-service/bundle';
import { BundlesGetIdenticalBundlesDetailParams } from '@/api-types/product-api';

const useIdenticalBundles = (
	bundleId: string,
	params?: Omit<BundlesGetIdenticalBundlesDetailParams, 'bundleId'>
) => {
	return useQuery(
		['identical-bundles', bundleId],
		() => BundleService.getIdenticalBundles(bundleId, params),
		{
			enabled: !!bundleId,
		}
	);
};

export default useIdenticalBundles;
