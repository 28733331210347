import {
	useBundleDetail,
	useStringifySetBundleParams,
} from 'Pages/Bundle/hooks';
import { DiscountPercentage, SetItem } from 'vinisto_ui';
import BundleInfoParams from 'Pages/Bundle/Components/BundleDetail/Components/BundleInfoParams';
import { BundleDataBundle } from 'Services/Bundle/interfaces';
import { useContext } from 'react';
import { LocalizationContext } from 'Services/LocalizationService';

const SetBundle = (set_data: BundleDataBundle) => {
	const getBundleSetParamsShort = useStringifySetBundleParams;

	const bundle_set_product = useBundleDetail(set_data);
	const activeCurrency = useContext(LocalizationContext).activeCurrency;
	const currency = activeCurrency?.currency;

	const { basePrice, discountedPrice } =
		bundle_set_product.domainBundle.bundlePrices;

	const basePriceWithVat = Math.max(
		basePrice?.valueWithVat ?? 0,
		set_data.setItem?.originalPrice?.valueWithVat ?? 0
	);

	const discountedOrBasePriceWithVat =
		discountedPrice?.valueWithVat ?? basePrice?.valueWithVat ?? 0;

	const set_price = Number(
		(set_data.setItem?.setPrice?.valueWithVat ?? 0).toFixed(2)
	);

	const set_params_short = getBundleSetParamsShort(
		set_data.specificationDetails ?? []
	);

	const hasSetItemBundleDiscount =
		(basePriceWithVat ?? 0) > (set_data.setItem?.setPrice?.valueWithVat ?? 0);

	const hasSetItemSetDiscount =
		(set_data.setItem?.setPrice?.valueWithVat ?? 0) <
		discountedOrBasePriceWithVat;

	const isSetItemDiscounted =
		(hasSetItemBundleDiscount || hasSetItemSetDiscount) &&
		new Set([set_price, basePriceWithVat, discountedOrBasePriceWithVat]).size >
			1;

	return (
		<SetItem
			imageUrl={bundle_set_product.bundleImageSmall}
			bundleName={bundle_set_product.bundleName}
			basicInfo={set_params_short}
			seller={bundle_set_product.supplierName}
			price={set_price}
			discountPercent={
				isSetItemDiscounted && (
					<DiscountPercentage
						discountedPriceWithVat={
							set_data.setItem?.setPrice?.valueWithVat ??
							discountedOrBasePriceWithVat
						}
						standardPriceWithVat={
							basePriceWithVat ?? discountedOrBasePriceWithVat
						}
					/>
				)
			}
			originalPrice={
				isSetItemDiscounted
					? basePriceWithVat ?? discountedOrBasePriceWithVat
					: null
			}
			bundleInfo={bundle_set_product.bundleDescription}
			params={
				<BundleInfoParams
					bundleParams={bundle_set_product?.specificationDetails ?? []}
				/>
			}
			translations={{
				seller: 'prodejce',
				more: 'Více',
				less: 'Méně',
				currency,
			}}
		/>
	);
};
export default SetBundle;
