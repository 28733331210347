import CategoryProvider from 'Components/CategoryProvider';
import HeaderPhoneIcon from 'Components/Icons/HeaderPhone';
import Config from 'Config';
import { ReactNode, useContext } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import Basket from '../Components/NavBasket';
import CategoryLinkList from '../Components/CategoryLinkList';
import { ICategoryLinkListItem } from '../Components/CategoryLinkList/interfaces';
import Favorites from '../Components/Favorites';
import Logo from '../Components/Logo';
import UserMenu from '../Components/UserMenu';
import Search from '../Components/Search';
import NavLink from '../Components/NavLink';
import pageMenuStyles from '../Components/NavLink/styles.module.css';
import { NavbarContext } from '../context';
import LanguagesSwitcher from '../Components/LanguageSwitcher';

import styles from './styles.module.css';

interface DesktopNavbarProps {
	navLinks?: {
		name: ReactNode;
		to: string;
	}[];
	staticCategories: ICategoryLinkListItem[];
}

const DesktopNavbar = ({ navLinks, staticCategories }: DesktopNavbarProps) => {
	const { headerRef } = useContext(NavbarContext);

	return (
		<header
			className={styles.mainHeader}
			ref={headerRef}
		>
			<div className="container">
				<div className="row justify-content-between">
					<div className="col logo-wrapper">
						<Logo />
					</div>
					<div className={cx('col', styles.languagesSwitcherCol)}>
						<LanguagesSwitcher />
					</div>
					<div className={cx(styles.searchWrapper, 'col search-wrapper')}>
						<Search />
					</div>
					<div className={cx(styles.functionButtonWrapper, 'col')}>
						<Favorites />
						<UserMenu />
						<Basket />
					</div>
				</div>
			</div>

			<div className="container px-0">
				<nav className="navbar navbar-expand navbar-light p-0 page-navbar">
					<ul className="navbar-nav underline-effect w-100 d-flex justify-content-between pe-0">
						{navLinks?.map(({ name, to, ...props }, index) => (
							<li
								key={index}
								className={'nav-item'}
							>
								<Link to={`${to}`}>
									<NavLink {...props}>{name}</NavLink>
								</Link>
							</li>
						))}
						<li
							key={(navLinks?.length ?? 0) + 1}
							className={'nav-item'}
						>
							<a href={`tel:${Config.market.phoneNumber}`}>
								<div
									className={cx(
										pageMenuStyles.navLink,
										pageMenuStyles.success,
										pageMenuStyles.withIcon,
										pageMenuStyles.withUnderline
									)}
									aria-current="page"
								>
									<div className={pageMenuStyles.iconWrapper}>
										<HeaderPhoneIcon />
									</div>
									<span className={pageMenuStyles.title}>
										{Config.market.phoneNumber}
									</span>
								</div>
							</a>
						</li>
					</ul>
				</nav>
			</div>
			<CategoryProvider typeCategoryHomePage={'HEAD'}>
				<CategoryLinkList links={staticCategories} />
			</CategoryProvider>
		</header>
	);
};

export default DesktopNavbar;
