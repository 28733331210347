import { useCallback, useContext, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { DiscountCouponService } from 'vinisto_api_client';
import { BasketService } from 'vinisto_api_client';
import { AuthenticationContext } from 'Services/AuthenticationService/context';
import { BasketContext } from 'Services/BasketService';
import { NotificationsContext } from 'Services/NotificationService';
import useLocalizedValue from 'Hooks/useLocalizedValue';
import { getPriceWithoutVAT } from 'vinisto_shared/src/price/get-price-without-vat';
import { LocalizationContext } from 'Services/LocalizationService';

import {
	VinistoCommonDllModelsApiCouponLimitationsLimitationDefinitionCategory,
	VinistoCommonDllModelsApiCouponLimitationsLimitationDefinitionSpecification,
	VinistoCommonDllModelsApiCouponLimitationsLimitationDefinitionSupplier,
	VinistoHelperDllEnumsDiscountCouponDiscountCouponType,
	VinistoOrderDllModelsApiDiscountCouponDefinitionModelsAmountDiscountCouponDefinition,
	VinistoOrderDllModelsApiDiscountCouponDefinitionModelsBaseDiscountCouponDefinition,
	VinistoOrderDllModelsApiDiscountCouponDefinitionModelsPercentageDiscountCouponDefinition,
} from '@/api-types/order-api';
import { VinistoHelperDllBaseBaseReturn } from '@/api-types/warehouse-api';
import { Bundle } from '@/domain/bundle';
import {
	VinistoHelperDllEnumsCountryCode,
	VinistoHelperDllEnumsCurrency,
	VinistoHelperDllEnumsVatRate,
} from '@/api-types/product-api';

const productDetailDiscountDouponsQueryParams = {
	CanBeApplied: true,
	IsCache: true,
	IsActive: true,
	isVisibleOnProductDetail: true,
	isReusable: true,
	limit: 30,
};

const { isPercentageTypeDiscountCoupon, isAmountTypeDiscountCoupon } =
	DiscountCouponService;

export const useGetDiscountCoupons = ({
	bundleId,
	currency,
	countryOfSale,
	supplierId,
}: {
	bundleId: string | undefined;
	currency: VinistoHelperDllEnumsCurrency;
	countryOfSale: VinistoHelperDllEnumsCountryCode;
	supplierId: string;
}) =>
	useQuery(
		[
			'discountCoupons',
			{
				...productDetailDiscountDouponsQueryParams,
				currency,
				countryOfSale,
				supplierId,
			},
			String(bundleId),
		],
		async () => {
			const [supplierCoupons, vinistoCoupons] = await Promise.all([
				DiscountCouponService.GetSupplierCoupons({
					...productDetailDiscountDouponsQueryParams,
					SearchSuppliers: [supplierId],
				}),
				DiscountCouponService.getAll({
					...productDetailDiscountDouponsQueryParams,
					//Currency: currency,
					//CountryOfSale: countryOfSale,
					SearchDiscountCouponType:
						VinistoHelperDllEnumsDiscountCouponDiscountCouponType.PERCENTAGE,
					IsSupplierDiscount: false,
				}),
			]);
			return { supplierCoupons, vinistoCoupons };
		},
		{
			enabled: !!bundleId,
			refetchOnMount: false,
		}
	);

export const useDiscountCoupons = ({ bundle }: { bundle: Bundle | null }) => {
	const { handleOnAddToBasket } = useContext(BasketContext);
	const { handleShowErrorNotification } = useContext(NotificationsContext);
	const { isLoggedIn, vinistoUser, anonymousUID } = useContext(
		AuthenticationContext
	);
	const getLocalizedValue = useLocalizedValue();
	const localizationContext = useContext(LocalizationContext);
	const {
		activeCurrency: { currency },
		countryOfSale,
		convertToActiveCurrencyIfPriceCurrencyIsDifferent,
	} = localizationContext;

	const url = getLocalizedValue(bundle?.url ?? []);
	const supplierId = bundle?.supplier?.id ?? '';

	const { isDiscounted, basePrice, discountedPrice } =
		bundle?.bundlePrices ?? {};

	const priceWithVat =
		(isDiscounted ? discountedPrice?.valueWithVat : basePrice?.valueWithVat) ??
		0;

	const { data: discountCouponsData, isLoading: isDiscountCouponsLoading } =
		useGetDiscountCoupons({
			bundleId: bundle?.id,
			currency,
			countryOfSale,
			supplierId,
		});

	const discountCouponsDataFilteredByLimitations = useMemo(() => {
		const filterByLimitation = (
			coupons: (
				| VinistoOrderDllModelsApiDiscountCouponDefinitionModelsAmountDiscountCouponDefinition
				| VinistoOrderDllModelsApiDiscountCouponDefinitionModelsPercentageDiscountCouponDefinition
			)[]
		) =>
			coupons?.filter((coupon) => {
				if (!coupon || !coupon.code) return false;
				if (
					coupon.limitationDefinition?.limitationType === 'CATEGORY_LIMITATION'
				) {
					return bundle?.categoryIds?.includes(
						(
							coupon.limitationDefinition as VinistoCommonDllModelsApiCouponLimitationsLimitationDefinitionCategory
						)?.categoryId ?? ''
					);
				}

				if (
					coupon.limitationDefinition?.limitationType ===
					'SPECIFICATION_LIMITATION'
				) {
					const couponSpecificationId = (
						coupon.limitationDefinition as VinistoCommonDllModelsApiCouponLimitationsLimitationDefinitionSpecification
					)?.specification?.definitionId;

					const bundleSpecificationWithMatchingId =
						bundle?.specificationDetails?.find(
							(detail) => detail.definition.id === couponSpecificationId
						);

					if (!bundleSpecificationWithMatchingId) return false;

					for (const allowedValue of (
						coupon.limitationDefinition as VinistoCommonDllModelsApiCouponLimitationsLimitationDefinitionSpecification
					)?.specification?.allowedValues ?? []) {
						if (
							bundleSpecificationWithMatchingId.value.selectedValueName ===
								allowedValue ||
							// @ts-expect-error Sadly, this is the actual shape of specification object (value.value)
							bundleSpecificationWithMatchingId.value?.value === allowedValue ||
							// @ts-expect-error dtto (selectedValueName and selectedValuesName here)
							bundleSpecificationWithMatchingId.selectedValuesName?.includes(
								allowedValue
							) ||
							Object.keys(
								// @ts-expect-error dtto
								bundleSpecificationWithMatchingId.definition?.allowedValues ??
									{}
							)?.includes(String(allowedValue))
						) {
							return true;
						}
					}
					return false;
				}

				if (
					coupon.limitationDefinition?.limitationType === 'SUPPLIER_LIMITATION'
				) {
					return (
						(
							coupon.limitationDefinition as VinistoCommonDllModelsApiCouponLimitationsLimitationDefinitionSupplier
						)?.supplierId === bundle?.supplier?.id
					);
				}

				return true;
			});
		return {
			supplierCoupons: filterByLimitation(
				discountCouponsData?.supplierCoupons ?? []
			),
			vinistoCoupons: filterByLimitation(
				discountCouponsData?.vinistoCoupons ?? []
			),
		};
	}, [
		bundle?.categoryIds,
		bundle?.specificationDetails,
		bundle?.supplier?.id,
		discountCouponsData?.supplierCoupons,
		discountCouponsData?.vinistoCoupons,
	]);

	const getDiscountCouponAbsoluteValue = useCallback(
		(
			coupon:
				| VinistoOrderDllModelsApiDiscountCouponDefinitionModelsBaseDiscountCouponDefinition
				| VinistoOrderDllModelsApiDiscountCouponDefinitionModelsAmountDiscountCouponDefinition
				| VinistoOrderDllModelsApiDiscountCouponDefinitionModelsPercentageDiscountCouponDefinition,
			benchmarkPrice: number
		) => {
			if (isPercentageTypeDiscountCoupon(coupon)) {
				return benchmarkPrice * (Number(coupon.percentageDiscount) / 100);
			}
			if (isAmountTypeDiscountCoupon(coupon)) {
				return convertToActiveCurrencyIfPriceCurrencyIsDifferent({
					price: coupon.amountDiscount?.value ?? 0,
					priceCurrency: coupon.amountDiscount?.currency ?? currency,
					activeCurrency: currency,
					rate: 'valueDiscountCoupons',
				});
			}
			return 0;
		},
		[convertToActiveCurrencyIfPriceCurrencyIsDifferent, currency]
	);

	const absoluteDiscountValuesByCoupon = useMemo(() => {
		const result: Record<string, number> = {};

		if (isDiscountCouponsLoading) return result;

		[
			...discountCouponsDataFilteredByLimitations.supplierCoupons,
			...discountCouponsDataFilteredByLimitations.vinistoCoupons,
		].forEach((coupon) => {
			if (!coupon || !coupon.code) return;

			const absoluteValue = getDiscountCouponAbsoluteValue(
				coupon,
				priceWithVat
			);

			result[coupon.code] = absoluteValue;
		});
		return result;
	}, [
		discountCouponsDataFilteredByLimitations,
		getDiscountCouponAbsoluteValue,
		isDiscountCouponsLoading,
		priceWithVat,
	]);

	const bestMatchingDiscountCoupon = useMemo(() => {
		if (isDiscountCouponsLoading) return null;

		const sortedCoupons = [
			discountCouponsDataFilteredByLimitations.supplierCoupons,
			discountCouponsDataFilteredByLimitations.vinistoCoupons,
		].flatMap((coupons) =>
			coupons
				?.filter((coupon) => {
					{
						if (!coupon || !coupon.code) return false;

						const fivePercentOfPrice = priceWithVat * 0.05;
						const fiftyPercentOfPrice = priceWithVat * 0.5;

						if (
							absoluteDiscountValuesByCoupon[String(coupon.code)] <
								fivePercentOfPrice ||
							absoluteDiscountValuesByCoupon[String(coupon.code)] >
								fiftyPercentOfPrice
						) {
							return false;
						}
						if (coupon.allowedFrom) {
							const convertedPriceWithVat =
								convertToActiveCurrencyIfPriceCurrencyIsDifferent({
									price: priceWithVat ?? 0,
									priceCurrency:
										basePrice?.currency ?? VinistoHelperDllEnumsCurrency.CZK,
									activeCurrency: currency,
								});

							const convertedAllowedFrom =
								convertToActiveCurrencyIfPriceCurrencyIsDifferent({
									price: coupon.allowedFrom?.value ?? 0,
									priceCurrency:
										coupon?.currency ?? VinistoHelperDllEnumsCurrency.CZK,
									activeCurrency: currency,
									rate: 'valueDiscountCoupons',
								});

							return convertedAllowedFrom <= convertedPriceWithVat;
						}
						return true;
					}
				})
				.sort((a, b) => {
					const aDiscountAbsoluteValue =
						absoluteDiscountValuesByCoupon[String(a.code)];

					const bDiscountAbsoluteValue =
						absoluteDiscountValuesByCoupon[String(b.code)];

					const aCreationDate = a.creationDate ?? 0;
					const bCreationDate = b.creationDate ?? 0;

					return (
						bDiscountAbsoluteValue - aDiscountAbsoluteValue ||
						bCreationDate - aCreationDate
					);
				})
		);
		return sortedCoupons?.[0] ?? null;
	}, [
		absoluteDiscountValuesByCoupon,
		basePrice?.currency,
		convertToActiveCurrencyIfPriceCurrencyIsDifferent,
		currency,
		discountCouponsDataFilteredByLimitations.supplierCoupons,
		discountCouponsDataFilteredByLimitations.vinistoCoupons,
		isDiscountCouponsLoading,
		priceWithVat,
	]);

	const isCouponAvailable = Boolean(bestMatchingDiscountCoupon);

	const priceWhenCouponApplied = bestMatchingDiscountCoupon
		? priceWithVat -
		  getDiscountCouponAbsoluteValue(bestMatchingDiscountCoupon, priceWithVat)
		: null;

	const priceWhenCouponAppliedWithoutVat = bestMatchingDiscountCoupon
		? getPriceWithoutVAT(
				priceWithVat -
					getDiscountCouponAbsoluteValue(
						bestMatchingDiscountCoupon,
						priceWithVat
					),
				basePrice?.vat ?? VinistoHelperDllEnumsVatRate.BaseVat
		  )
		: null;

	const bestMatchingDiscountCouponCode =
		bestMatchingDiscountCoupon?.code ?? null;

	const handleOnAddToBasketWithDiscountCoupon = useCallback(
		async ({ redirectToCrossell = true }) => {
			await handleOnAddToBasket(
				1,
				String(bundle?.id),
				url,
				undefined,
				redirectToCrossell
			);
			await BasketService.applyDiscountCoupon({
				discountCouponCode: bestMatchingDiscountCouponCode,
				...(isLoggedIn
					? { userLoginHash: vinistoUser.loginHash }
					: { anonymousUserId: anonymousUID.anonymousUserId }),
				currency,
				countryOfSale,
			}).catch((responseError: VinistoHelperDllBaseBaseReturn) => {
				const isCouponAlreadyApplied = responseError?.error?.some(
					(err) =>
						err.specificError === 'DISCOUNT_COUPON_ID_IS_ALREADY_IN_BASKET'
				);

				const isCouponNotCombinable = responseError?.error?.some(
					(err) => err.specificError === 'DISCOUNT_COUPON_IS_NOT_COMBINABLE'
				);

				if (!(isCouponAlreadyApplied || isCouponNotCombinable)) {
					handleShowErrorNotification('basket.discountCoupon.error');
				}
			});
		},
		[
			handleOnAddToBasket,
			bundle?.id,
			url,
			bestMatchingDiscountCouponCode,
			isLoggedIn,
			vinistoUser.loginHash,
			anonymousUID.anonymousUserId,
			currency,
			countryOfSale,
			handleShowErrorNotification,
		]
	);

	return {
		mostValuableDiscountCoupon: bestMatchingDiscountCoupon,
		isCouponAvailable,
		priceWhenCouponApplied,
		priceWhenCouponAppliedWithoutVat,
		mostValuableDiscountCouponCode: bestMatchingDiscountCouponCode,
		handleOnAddToBasketWithDiscountCoupon,
	};
};
