import { BasketContext } from 'Services/BasketService';
import { DeviceServiceContext } from 'Services/DeviceService';
import { LocalizationContext } from 'Services/LocalizationService';
import { useContext } from 'react';
import { Button, buttonSizes, buttonVariants } from 'vinisto_ui';
import { VolumeDiscount } from 'vinisto_ui';
import { AuthenticationContext } from 'Services/AuthenticationService/context';
import { ModalContext } from 'Components/Modal/context';
import { LOGIN_MODAL } from 'Components/Modal/constants';
import { getLocalizedPrice } from 'vinisto_shared/src/price/get-localized-price';

import { VolumeDiscount as VolumeDiscountType } from '@/domain/price';

interface VolumeDiscountSectionProps {
	bundleId: string;
	isForLogged: boolean;
	volumeDiscount: VolumeDiscountType;
	warehouseCount: number;
	bundleLimitPerOrder: number | null;
}

const VolumeDiscountSection = ({
	bundleId,
	isForLogged = false,
	volumeDiscount,
	warehouseCount,
	bundleLimitPerOrder,
}: VolumeDiscountSectionProps) => {
	const t = useContext(LocalizationContext).useFormatMessage();
	const { isDesktop } = useContext(DeviceServiceContext);
	const { basketState, handleOnChangeItemQuantity } = useContext(BasketContext);
	const authenticationContext = useContext(AuthenticationContext);
	const modalContext = useContext(ModalContext);

	const itemInBasket = basketState?.items?.find(
		(item) => item?.bundleId === bundleId
	);

	const quantityInBasket = itemInBasket?.quantity ?? 0;

	const getWouldExceedOrderLimitation = (quantity: number) =>
		typeof bundleLimitPerOrder === 'number' &&
		bundleLimitPerOrder <= quantity + (itemInBasket?.quantity ?? 0);

	const values = volumeDiscount.values.filter((item) => {
		if (item.quantity > warehouseCount) return false;
		if (Math.round(item.totalSavingsWithVat) < 1) return false;
		if (getWouldExceedOrderLimitation(quantityInBasket + item.quantity))
			return false;

		return true;
	});

	if (values.length === 0) {
		return null;
	}

	return (
		<VolumeDiscount.Container
			title={t({
				id: isDesktop
					? 'volumeDiscount.title.desktop'
					: 'volumeDiscount.title.mobile',
			})}
		>
			<VolumeDiscount.List>
				{values.map((item, index) => {
					return (
						<VolumeDiscount.ListItem
							key={index}
							text={t(
								{ id: 'volumeDiscount.content' },
								{
									amount: (
										<VolumeDiscount.Emphasized>
											{t({ id: 'amount.pcs' }, { count: item.quantity })}
										</VolumeDiscount.Emphasized>
									),
									cumulativePrice: (
										<VolumeDiscount.DoubleEmphasized>
											{getLocalizedPrice({
												price: item.totalValueWithVat,
												currency: volumeDiscount.currency,
											})}
										</VolumeDiscount.DoubleEmphasized>
									),
									savings: (
										<VolumeDiscount.Emphasized>
											{getLocalizedPrice({
												price: item.totalSavingsWithVat,
												currency: volumeDiscount.currency,
											})}
										</VolumeDiscount.Emphasized>
									),
								}
							)}
							cta={
								!authenticationContext.isLoggedIn && isForLogged ? (
									<Button
										variant={buttonVariants.CTA}
										size={buttonSizes.S}
										onClick={() =>
											modalContext.handleOpenModal(LOGIN_MODAL, {})
										}
									>
										{t({ id: 'carousel.button.loginAndShop' })}
									</Button>
								) : (
									<Button
										variant={buttonVariants.CTA}
										size={buttonSizes.S}
										onClick={() =>
											handleOnChangeItemQuantity(
												quantityInBasket + item.quantity,
												bundleId
											)
										}
									>
										{t({ id: 'bundle.button.buy' })}
									</Button>
								)
							}
						/>
					);
				})}
			</VolumeDiscount.List>
		</VolumeDiscount.Container>
	);
};

export default VolumeDiscountSection;
